import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  Date: { input: string; output: string; }
  /** Scalar with date and time information */
  DateTime: { input: string; output: string; }
  /** Custom scalar that serializes JSON as a string and deserializes string back to JSON */
  Json: { input: any; output: any; }
  /** Scalar representing a UUID */
  Uuid: { input: string; output: string; }
};

export type AnalyticsCountType =
  | 'COMMENT_CREATED'
  | 'HOME_VIEWED'
  | 'HOME_VIEWED_UNIQUE'
  | 'POST_CREATED'
  | 'POST_LIKED'
  | 'POST_SHARED'
  | 'POST_VIEWED'
  | 'PROFILE_VIEWED'
  | 'SHOP_OPENED'
  | 'USER_JOINED';

export type AnalyticsEventCount = {
  __typename?: 'AnalyticsEventCount';
  brandId: Scalars['Uuid']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  type: AnalyticsCountType;
  updatedAt: Scalars['DateTime']['output'];
};

/** Input type for approvePost mutation */
export type ApprovePostInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for approvePost mutation */
export type ApprovePostPayload = {
  __typename?: 'ApprovePostPayload';
  post: Post;
};

/** Input type for banBrandMember mutation */
export type BanBrandMemberInput = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

/** Payload type for banBrandMember mutation */
export type BanBrandMemberPayload = {
  __typename?: 'BanBrandMemberPayload';
  brandMember: BrandMember;
};

export type BannedWord = {
  __typename?: 'BannedWord';
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  word: Scalars['String']['output'];
};

export type BannedWordCreateData = {
  brandId: Scalars['Uuid']['input'];
  id?: InputMaybe<Scalars['Uuid']['input']>;
  word: Scalars['String']['input'];
};

export type BannedWordUpdateData = {
  word?: InputMaybe<Scalars['String']['input']>;
};

export type Brand = {
  __typename?: 'Brand';
  about?: Maybe<Scalars['String']['output']>;
  analyticsEventCounts: Array<AnalyticsEventCount>;
  analyticsKey?: Maybe<Scalars['String']['output']>;
  borderColor?: Maybe<Scalars['String']['output']>;
  brandHeaderTextColor?: Maybe<Scalars['String']['output']>;
  brandKlaviyoIntegration?: Maybe<BrandKlaviyoIntegration>;
  brandLinks: Array<BrandLink>;
  brandLinksSectionTitle: Scalars['String']['output'];
  brandMemberPermissions: Array<Scalars['String']['output']>;
  challenges: Array<Challenge>;
  challengesCount: Scalars['Int']['output'];
  communityDomain: Scalars['String']['output'];
  communityGuidelines?: Maybe<Scalars['String']['output']>;
  contentBackgroundColor?: Maybe<Scalars['String']['output']>;
  contentFont?: Maybe<BrandFontOption>;
  contentFontId?: Maybe<Scalars['Uuid']['output']>;
  contentTextColor?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customAuthUrl?: Maybe<Scalars['String']['output']>;
  customLogoutUrl?: Maybe<Scalars['String']['output']>;
  customOauth?: Maybe<BrandCustomOauth>;
  customTraitGroups: Array<CustomTraitGroup>;
  customUserSettingsUrl?: Maybe<Scalars['String']['output']>;
  emailDigestIntroMessage: Scalars['String']['output'];
  enabledFonts: Array<BrandEnabledFont>;
  events: Array<BrandEvent>;
  faq: Array<Faq>;
  faviconFile?: Maybe<File>;
  hasHairTraits?: Maybe<Scalars['Boolean']['output']>;
  hasProducts: Scalars['Boolean']['output'];
  hasShoeTraits?: Maybe<Scalars['Boolean']['output']>;
  hasSkinTraits?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use 'isYotpoLoyaltyEnabled' instead */
  hasYotpoIntegration: Scalars['Boolean']['output'];
  headerBackgroundFile?: Maybe<File>;
  headerButtonIcon: HeaderButtonIconType;
  headerButtonOpenInNewWindow: Scalars['Boolean']['output'];
  headerButtonText: Scalars['String']['output'];
  headerFont?: Maybe<BrandFontOption>;
  headerFontId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  inveterateIntegration?: Maybe<BrandInveterateIntegration>;
  isEmailDigestEnabled: Scalars['Boolean']['output'];
  isMembershipGated: Scalars['Boolean']['output'];
  isPhoneVerificationEnabled?: Maybe<Scalars['Boolean']['output']>;
  isPostApprovalRequired: Scalars['Boolean']['output'];
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  isShopifyEnabled: Scalars['Boolean']['output'];
  isTrial?: Maybe<Scalars['Boolean']['output']>;
  isYotpoLoyaltyEnabled: Scalars['Boolean']['output'];
  logoFile?: Maybe<File>;
  loyaltyActions: Array<LoyaltyAction>;
  loyaltyEnabled: Scalars['Boolean']['output'];
  memberCount: Scalars['Int']['output'];
  members: Array<BrandMember>;
  membershipJoinUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newMembers?: Maybe<Array<BrandMember>>;
  pageBackgroundColor?: Maybe<Scalars['String']['output']>;
  pageTextColor?: Maybe<Scalars['String']['output']>;
  plausibleDomain?: Maybe<Scalars['String']['output']>;
  plausibleEmbedLink?: Maybe<Scalars['String']['output']>;
  postCount: Scalars['Int']['output'];
  posts: Array<Post>;
  postsPendingApprovalCount?: Maybe<Scalars['Int']['output']>;
  primaryBackgroundColor?: Maybe<Scalars['String']['output']>;
  primaryTextColor?: Maybe<Scalars['String']['output']>;
  products: Array<BrandProduct>;
  replyToEmail?: Maybe<Scalars['String']['output']>;
  rooms: Array<RoomOption>;
  secondaryBackgroundColor?: Maybe<Scalars['String']['output']>;
  secondaryTextColor?: Maybe<Scalars['String']['output']>;
  shopUrl?: Maybe<Scalars['String']['output']>;
  shopifyIntegration?: Maybe<BrandShopifyIntegration>;
  shouldShowPWA: Scalars['Boolean']['output'];
  sitemapMembers?: Maybe<Array<BrandMember>>;
  sitemapPosts?: Maybe<Array<Post>>;
  tertiaryBackgroundColor?: Maybe<Scalars['String']['output']>;
  tertiaryTextColor?: Maybe<Scalars['String']['output']>;
  trialEndsAt?: Maybe<Scalars['DateTime']['output']>;
  usesCustomAuth: Scalars['Boolean']['output'];
  welcomeBannerButtonText?: Maybe<Scalars['String']['output']>;
  welcomeBannerTitle?: Maybe<Scalars['String']['output']>;
  welcomeCtaBody?: Maybe<Scalars['String']['output']>;
  welcomeCtaDiscount?: Maybe<Scalars['String']['output']>;
  welcomeCtaEnabled: Scalars['Boolean']['output'];
  welcomeCtaTitle?: Maybe<Scalars['String']['output']>;
  welcomeMessage?: Maybe<Scalars['String']['output']>;
  welcomeModalLogoFile?: Maybe<File>;
  yotpoDeveloperIntegration?: Maybe<BrandYotpoDeveloperIntegration>;
  yotpoIntegration?: Maybe<BrandYotpoIntegration>;
  yotpoRedemptionOptions: Array<BrandYotpoRedemptionOption>;
  yotpoRedemptionUrl?: Maybe<Scalars['String']['output']>;
};


export type BrandAnalyticsEventCountsArgs = {
  daysHistory?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type BrandRoomsArgs = {
  isDefaultRoom?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrandApiKey = {
  __typename?: 'BrandApiKey';
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  keyPrefix: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scopes: Array<BrandApiKeyScope>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BrandApiKeyCreateData = {
  brandId: Scalars['Uuid']['input'];
  name: Scalars['String']['input'];
  scopes?: InputMaybe<Array<BrandApiKeyEmbeddedScopesData>>;
};

export type BrandApiKeyEmbeddedScopesData = {
  id?: InputMaybe<Scalars['Uuid']['input']>;
  scope: Scalars['String']['input'];
};

export type BrandApiKeyScope = {
  __typename?: 'BrandApiKeyScope';
  brandApiKeyId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  scope: Scalars['String']['output'];
};

export type BrandApiKeyUpdateData = {
  name?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<BrandApiKeyEmbeddedScopesData>>;
};

export type BrandCreateData = {
  about?: InputMaybe<Scalars['String']['input']>;
  analyticsKey?: InputMaybe<Scalars['String']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  brandHeaderTextColor?: InputMaybe<Scalars['String']['input']>;
  brandLinksSectionTitle?: InputMaybe<Scalars['String']['input']>;
  communityDomain: Scalars['String']['input'];
  communityGuidelines?: InputMaybe<Scalars['String']['input']>;
  contentBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  contentFontId?: InputMaybe<Scalars['Uuid']['input']>;
  contentTextColor?: InputMaybe<Scalars['String']['input']>;
  customAuthUrl?: InputMaybe<Scalars['String']['input']>;
  customLogoutUrl?: InputMaybe<Scalars['String']['input']>;
  customOauth?: InputMaybe<BrandEmbeddedCustomOauthData>;
  customUserSettingsUrl?: InputMaybe<Scalars['String']['input']>;
  enabledFonts?: InputMaybe<Array<BrandEmbeddedEnabledFontsData>>;
  faviconFile?: InputMaybe<FileUploadInput>;
  hasHairTraits?: InputMaybe<Scalars['Boolean']['input']>;
  hasShoeTraits?: InputMaybe<Scalars['Boolean']['input']>;
  hasSkinTraits?: InputMaybe<Scalars['Boolean']['input']>;
  headerBackgroundFile?: InputMaybe<FileUploadInput>;
  headerButtonIcon?: InputMaybe<HeaderButtonIconType>;
  headerButtonOpenInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  headerButtonText?: InputMaybe<Scalars['String']['input']>;
  headerFontId?: InputMaybe<Scalars['Uuid']['input']>;
  isPhoneVerificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  isTrial?: InputMaybe<Scalars['Boolean']['input']>;
  logoFile?: InputMaybe<FileUploadInput>;
  name: Scalars['String']['input'];
  pageBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  pageTextColor?: InputMaybe<Scalars['String']['input']>;
  primaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  primaryTextColor?: InputMaybe<Scalars['String']['input']>;
  secondaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  secondaryTextColor?: InputMaybe<Scalars['String']['input']>;
  shopUrl?: InputMaybe<Scalars['String']['input']>;
  shouldShowPWA?: InputMaybe<Scalars['Boolean']['input']>;
  tertiaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  tertiaryTextColor?: InputMaybe<Scalars['String']['input']>;
  trialEndsAt?: InputMaybe<Scalars['DateTime']['input']>;
  usesCustomAuth?: InputMaybe<Scalars['Boolean']['input']>;
  welcomeBannerButtonText?: InputMaybe<Scalars['String']['input']>;
  welcomeBannerTitle?: InputMaybe<Scalars['String']['input']>;
  welcomeCtaBody?: InputMaybe<Scalars['String']['input']>;
  welcomeCtaDiscount?: InputMaybe<Scalars['String']['input']>;
  welcomeCtaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  welcomeCtaTitle?: InputMaybe<Scalars['String']['input']>;
  welcomeMessage?: InputMaybe<Scalars['String']['input']>;
  welcomeModalLogoFile?: InputMaybe<FileUploadInput>;
};

export type BrandCustomOauth = {
  __typename?: 'BrandCustomOauth';
  accessUrl: Scalars['String']['output'];
  authorizeUrl: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jwksEndpointUrl: Scalars['String']['output'];
  jwtIssuer: Scalars['String']['output'];
  oauthVersion: Scalars['Int']['output'];
};

export type BrandEmbeddedCustomOauthData = {
  accessUrl: Scalars['String']['input'];
  authorizeUrl: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  jwksEndpointUrl: Scalars['String']['input'];
  jwtIssuer: Scalars['String']['input'];
  oauthVersion?: InputMaybe<Scalars['Int']['input']>;
};

export type BrandEmbeddedEnabledFontsData = {
  fontOptionId: Scalars['Uuid']['input'];
};

export type BrandEmbeddedLoyaltyActionsData = {
  earnPeriod?: InputMaybe<LoyaltyActionPeriod>;
  earnRate?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  points: Scalars['Int']['input'];
  type: LoyaltyActionType;
};

export type BrandEnabledFont = {
  __typename?: 'BrandEnabledFont';
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  font: BrandFontOption;
  fontOptionId: Scalars['Uuid']['output'];
};

export type BrandEvent = {
  __typename?: 'BrandEvent';
  attendees: Array<EventAttendee>;
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slots: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userRsvpId?: Maybe<Scalars['String']['output']>;
};


export type BrandEventUserRsvpIdArgs = {
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type BrandEventCreateData = {
  brandId: Scalars['Uuid']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  location: Scalars['String']['input'];
  name: Scalars['String']['input'];
  slots: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type BrandEventUpdateData = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slots?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type BrandFontOption = {
  __typename?: 'BrandFontOption';
  createdAt: Scalars['DateTime']['output'];
  fontSrc?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isGoogle: Scalars['Boolean']['output'];
  isRestricted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BrandInveterateIntegration = {
  __typename?: 'BrandInveterateIntegration';
  id: Scalars['ID']['output'];
  isCommunityGateEnabled: Scalars['Boolean']['output'];
  membershipJoinUrl?: Maybe<Scalars['String']['output']>;
};

export type BrandKlaviyoIntegration = {
  __typename?: 'BrandKlaviyoIntegration';
  brandId: Scalars['ID']['output'];
  brandKlaviyoMappings: Array<BrandKlaviyoMapping>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  klaviyoListId?: Maybe<Scalars['String']['output']>;
  klaviyoLists: Array<KlaviyoList>;
  lastSyncAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BrandKlaviyoIntegrationCreateData = {
  brandId?: InputMaybe<Scalars['Uuid']['input']>;
  brandKlaviyoMappings?: InputMaybe<Array<BrandKlaviyoIntegrationEmbeddedBrandKlaviyoMappingsData>>;
  klaviyoApiKey: Scalars['String']['input'];
  klaviyoListId?: InputMaybe<Scalars['String']['input']>;
};

export type BrandKlaviyoIntegrationEmbeddedBrandKlaviyoMappingsData = {
  addGlowProperty: KlaviyoProperty;
  destinationProperty: Scalars['String']['input'];
  isEnabled: Scalars['Boolean']['input'];
};

export type BrandKlaviyoIntegrationUpdateData = {
  brandKlaviyoMappings?: InputMaybe<Array<BrandKlaviyoIntegrationEmbeddedBrandKlaviyoMappingsData>>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  klaviyoApiKey?: InputMaybe<Scalars['String']['input']>;
  klaviyoListId?: InputMaybe<Scalars['String']['input']>;
};

export type BrandKlaviyoMapping = {
  __typename?: 'BrandKlaviyoMapping';
  addGlowProperty: KlaviyoProperty;
  brandId: Scalars['Uuid']['output'];
  destinationProperty: Scalars['String']['output'];
  isEnabled: Scalars['Boolean']['output'];
};

export type BrandLink = {
  __typename?: 'BrandLink';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  openInNewWindow: Scalars['Boolean']['output'];
  restrictToLoggedInUsers: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type BrandLinkCreateData = {
  brandId: Scalars['Uuid']['input'];
  label: Scalars['String']['input'];
  openInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  restrictToLoggedInUsers?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type BrandLinkUpdateData = {
  label?: InputMaybe<Scalars['String']['input']>;
  openInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  restrictToLoggedInUsers?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type BrandLogEntry = {
  __typename?: 'BrandLogEntry';
  action: Scalars['String']['output'];
  actionKey: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  info: Scalars['Json']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Uuid']['output']>;
  userIdArchived: Scalars['Uuid']['output'];
};

export type BrandLogEntryConnection = {
  __typename?: 'BrandLogEntryConnection';
  edges: Array<BrandLogEntryEdge>;
  pageInfo: PageInfo;
};

export type BrandLogEntryEdge = {
  __typename?: 'BrandLogEntryEdge';
  cursor: Scalars['String']['output'];
  node: BrandLogEntry;
};

export type BrandLogEntryWhereInput = {
  brandId: Scalars['Uuid']['input'];
};

export type BrandMember = {
  __typename?: 'BrandMember';
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  groupMemberships: Array<MemberGroupMember>;
  hasEmailMarketingOptIn?: Maybe<Scalars['Boolean']['output']>;
  isBanned?: Maybe<Scalars['Boolean']['output']>;
  isConversationStarter: Scalars['Boolean']['output'];
  isTopContributor: Scalars['Boolean']['output'];
  labels: Array<BrandMemberLabel>;
  lastLoyaltyPointReminderEmailAt?: Maybe<Scalars['DateTime']['output']>;
  lastOpenedNotificationsAt: Scalars['DateTime']['output'];
  lifetimeLoyaltyPoints: Scalars['Int']['output'];
  loyaltyPoints: Scalars['Int']['output'];
  onboarding?: Maybe<Onboarding>;
  referrer?: Maybe<BrandMember>;
  role: BrandMemberRole;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['Uuid']['output'];
  welcomeEmailSeries?: Maybe<WelcomeEmailSeries>;
};

export type BrandMemberBadge = {
  __typename?: 'BrandMemberBadge';
  backgroundColor: Scalars['String']['output'];
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  foregroundColor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  memberGroup?: Maybe<MemberGroup>;
  memberGroupId?: Maybe<Scalars['Uuid']['output']>;
  name: Scalars['String']['output'];
  type: BrandMemberBadgeType;
  updatedAt: Scalars['DateTime']['output'];
};

export type BrandMemberBadgeCreateData = {
  backgroundColor: Scalars['String']['input'];
  brandId: Scalars['Uuid']['input'];
  foregroundColor: Scalars['String']['input'];
  memberGroupId?: InputMaybe<Scalars['Uuid']['input']>;
  name: Scalars['String']['input'];
  type: BrandMemberBadgeType;
};

export type BrandMemberBadgeType =
  | 'ADMIN'
  | 'GROUP'
  | 'MODERATOR'
  | 'ROOM_MODERATOR';

export type BrandMemberBadgeUpdateData = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  foregroundColor?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandMemberConnection = {
  __typename?: 'BrandMemberConnection';
  edges: Array<BrandMemberEdge>;
  pageInfo: PageInfo;
};

export type BrandMemberCreateData = {
  brandId: Scalars['Uuid']['input'];
  hasEmailMarketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  isConversationStarter?: InputMaybe<Scalars['Boolean']['input']>;
  isTopContributor?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoyaltyPointReminderEmailAt?: InputMaybe<Scalars['DateTime']['input']>;
  referrerUserId?: InputMaybe<Scalars['Uuid']['input']>;
  role: BrandMemberRole;
  userId: Scalars['Uuid']['input'];
};

export type BrandMemberEdge = {
  __typename?: 'BrandMemberEdge';
  cursor: Scalars['String']['output'];
  node: BrandMember;
};

export type BrandMemberLabel = {
  __typename?: 'BrandMemberLabel';
  brandId: Scalars['Uuid']['output'];
  brandMember: BrandMember;
  brandMemberLabelOptionId: Scalars['Uuid']['output'];
  labelOption: BrandMemberLabelOption;
  userId: Scalars['Uuid']['output'];
};

export type BrandMemberLabelCreateData = {
  brandId: Scalars['Uuid']['input'];
  brandMemberLabelOptionId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type BrandMemberLabelOption = {
  __typename?: 'BrandMemberLabelOption';
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type BrandMemberLabelOptionCreateData = {
  brandId: Scalars['Uuid']['input'];
  color: Scalars['String']['input'];
  isAdmin: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type BrandMemberLabelOptionUpdateData = {
  color?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandMemberLabelPrimaryKey = {
  brandId: Scalars['Uuid']['input'];
  brandMemberLabelOptionId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type BrandMemberLabelUpdateData = {
  brandMemberLabelOptionId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type BrandMemberNotificationSetting = {
  __typename?: 'BrandMemberNotificationSetting';
  adminNewSupportPostEmail: Scalars['Boolean']['output'];
  adminUnrespondedPostEmail: Scalars['Boolean']['output'];
  brandId: Scalars['Uuid']['output'];
  challengeEmail: Scalars['Boolean']['output'];
  commentRepliesApp: Scalars['Boolean']['output'];
  commentRepliesEmail: Scalars['Boolean']['output'];
  digestsEmail: Scalars['Boolean']['output'];
  eventEmail: Scalars['Boolean']['output'];
  likesApp: Scalars['Boolean']['output'];
  loyaltyPointReminderEmail: Scalars['Boolean']['output'];
  mentionsApp: Scalars['Boolean']['output'];
  mentionsEmail: Scalars['Boolean']['output'];
  onboardingEmail: Scalars['Boolean']['output'];
  postEmail: Scalars['Boolean']['output'];
  postPollApp: Scalars['Boolean']['output'];
  postPollEmail: Scalars['Boolean']['output'];
  postRepliesApp: Scalars['Boolean']['output'];
  postRepliesEmail: Scalars['Boolean']['output'];
  userId: Scalars['Uuid']['output'];
};

export type BrandMemberNotificationSettingCreateData = {
  adminNewSupportPostEmail?: InputMaybe<Scalars['Boolean']['input']>;
  adminUnrespondedPostEmail?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['Uuid']['input'];
  challengeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  commentRepliesApp?: InputMaybe<Scalars['Boolean']['input']>;
  commentRepliesEmail?: InputMaybe<Scalars['Boolean']['input']>;
  digestsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  eventEmail?: InputMaybe<Scalars['Boolean']['input']>;
  likesApp?: InputMaybe<Scalars['Boolean']['input']>;
  loyaltyPointReminderEmail?: InputMaybe<Scalars['Boolean']['input']>;
  mentionsApp?: InputMaybe<Scalars['Boolean']['input']>;
  mentionsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingEmail?: InputMaybe<Scalars['Boolean']['input']>;
  postEmail?: InputMaybe<Scalars['Boolean']['input']>;
  postPollApp?: InputMaybe<Scalars['Boolean']['input']>;
  postPollEmail?: InputMaybe<Scalars['Boolean']['input']>;
  postRepliesApp?: InputMaybe<Scalars['Boolean']['input']>;
  postRepliesEmail?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['Uuid']['input'];
};

export type BrandMemberNotificationSettingPrimaryKey = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type BrandMemberNotificationSettingUpdateData = {
  adminNewSupportPostEmail?: InputMaybe<Scalars['Boolean']['input']>;
  adminUnrespondedPostEmail?: InputMaybe<Scalars['Boolean']['input']>;
  brandId?: InputMaybe<Scalars['Uuid']['input']>;
  challengeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  commentRepliesApp?: InputMaybe<Scalars['Boolean']['input']>;
  commentRepliesEmail?: InputMaybe<Scalars['Boolean']['input']>;
  digestsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  eventEmail?: InputMaybe<Scalars['Boolean']['input']>;
  likesApp?: InputMaybe<Scalars['Boolean']['input']>;
  loyaltyPointReminderEmail?: InputMaybe<Scalars['Boolean']['input']>;
  mentionsApp?: InputMaybe<Scalars['Boolean']['input']>;
  mentionsEmail?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingEmail?: InputMaybe<Scalars['Boolean']['input']>;
  postEmail?: InputMaybe<Scalars['Boolean']['input']>;
  postPollApp?: InputMaybe<Scalars['Boolean']['input']>;
  postPollEmail?: InputMaybe<Scalars['Boolean']['input']>;
  postRepliesApp?: InputMaybe<Scalars['Boolean']['input']>;
  postRepliesEmail?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type BrandMemberPrimaryKey = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type BrandMemberRole =
  | 'ADMIN'
  | 'CUSTOMER'
  | 'MODERATOR';

export type BrandMemberUpdateData = {
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  isConversationStarter?: InputMaybe<Scalars['Boolean']['input']>;
  isTopContributor?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoyaltyPointReminderEmailAt?: InputMaybe<Scalars['DateTime']['input']>;
  memberGroupIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  role?: InputMaybe<BrandMemberRole>;
};

export type BrandMembersSortByOption =
  | 'DATE_JOINED'
  | 'LIFETIME_POINTS'
  | 'NAME';

export type BrandProduct = {
  __typename?: 'BrandProduct';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageHeight?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  imageWidth?: Maybe<Scalars['Int']['output']>;
  onlineStoreUrl: Scalars['String']['output'];
  postProductMentions: Array<PostProductMention>;
  storeId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  variants: Array<BrandProductVariant>;
};

export type BrandProductVariant = {
  __typename?: 'BrandProductVariant';
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageHeight?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  imageWidth?: Maybe<Scalars['Int']['output']>;
  position: Scalars['Int']['output'];
  priceCents?: Maybe<Scalars['Int']['output']>;
  productId: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
};

export type BrandShopifyIntegration = {
  __typename?: 'BrandShopifyIntegration';
  apiKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isConnected: Scalars['Boolean']['output'];
  isProductSyncEnabled?: Maybe<Scalars['Boolean']['output']>;
  shopUrl?: Maybe<Scalars['String']['output']>;
  shopifyAppName: Scalars['String']['output'];
};

export type BrandUpdateData = {
  about?: InputMaybe<Scalars['String']['input']>;
  analyticsKey?: InputMaybe<Scalars['String']['input']>;
  borderColor?: InputMaybe<Scalars['String']['input']>;
  brandHeaderTextColor?: InputMaybe<Scalars['String']['input']>;
  brandLinksSectionTitle?: InputMaybe<Scalars['String']['input']>;
  communityDomain?: InputMaybe<Scalars['String']['input']>;
  communityGuidelines?: InputMaybe<Scalars['String']['input']>;
  contentBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  contentFontId?: InputMaybe<Scalars['Uuid']['input']>;
  contentTextColor?: InputMaybe<Scalars['String']['input']>;
  customAuthUrl?: InputMaybe<Scalars['String']['input']>;
  customLogoutUrl?: InputMaybe<Scalars['String']['input']>;
  customOauth?: InputMaybe<BrandEmbeddedCustomOauthData>;
  customUserSettingsUrl?: InputMaybe<Scalars['String']['input']>;
  emailDigestIntroMessage?: InputMaybe<Scalars['String']['input']>;
  enabledFonts?: InputMaybe<Array<BrandEmbeddedEnabledFontsData>>;
  faviconFile?: InputMaybe<FileUploadInput>;
  hasHairTraits?: InputMaybe<Scalars['Boolean']['input']>;
  hasShoeTraits?: InputMaybe<Scalars['Boolean']['input']>;
  hasSkinTraits?: InputMaybe<Scalars['Boolean']['input']>;
  headerBackgroundFile?: InputMaybe<FileUploadInput>;
  headerButtonIcon?: InputMaybe<HeaderButtonIconType>;
  headerButtonOpenInNewWindow?: InputMaybe<Scalars['Boolean']['input']>;
  headerButtonText?: InputMaybe<Scalars['String']['input']>;
  headerFontId?: InputMaybe<Scalars['Uuid']['input']>;
  isEmailDigestEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPhoneVerificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPostApprovalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  isTrial?: InputMaybe<Scalars['Boolean']['input']>;
  logoFile?: InputMaybe<FileUploadInput>;
  loyaltyActions?: InputMaybe<Array<BrandEmbeddedLoyaltyActionsData>>;
  loyaltyEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  pageTextColor?: InputMaybe<Scalars['String']['input']>;
  primaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  primaryTextColor?: InputMaybe<Scalars['String']['input']>;
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  secondaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  secondaryTextColor?: InputMaybe<Scalars['String']['input']>;
  shopUrl?: InputMaybe<Scalars['String']['input']>;
  shouldShowPWA?: InputMaybe<Scalars['Boolean']['input']>;
  tertiaryBackgroundColor?: InputMaybe<Scalars['String']['input']>;
  tertiaryTextColor?: InputMaybe<Scalars['String']['input']>;
  trialEndsAt?: InputMaybe<Scalars['DateTime']['input']>;
  usesCustomAuth?: InputMaybe<Scalars['Boolean']['input']>;
  welcomeBannerButtonText?: InputMaybe<Scalars['String']['input']>;
  welcomeBannerTitle?: InputMaybe<Scalars['String']['input']>;
  welcomeCtaBody?: InputMaybe<Scalars['String']['input']>;
  welcomeCtaDiscount?: InputMaybe<Scalars['String']['input']>;
  welcomeCtaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  welcomeCtaTitle?: InputMaybe<Scalars['String']['input']>;
  welcomeMessage?: InputMaybe<Scalars['String']['input']>;
  welcomeModalLogoFile?: InputMaybe<FileUploadInput>;
};

export type BrandWebhook = {
  __typename?: 'BrandWebhook';
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endpointUrl: Scalars['String']['output'];
  eventTypes: Array<BrandWebhookEventType>;
  events: Array<BrandWebhookEvent>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  signingSecret: Scalars['String']['output'];
};

export type BrandWebhookCreateData = {
  brandId: Scalars['Uuid']['input'];
  description: Scalars['String']['input'];
  endpointUrl: Scalars['String']['input'];
  eventTypes?: InputMaybe<Array<BrandWebhookEmbeddedEventTypesData>>;
};

export type BrandWebhookEmbeddedEventTypesData = {
  eventType: Scalars['String']['input'];
};

export type BrandWebhookEvent = {
  __typename?: 'BrandWebhookEvent';
  attempts: Scalars['Int']['output'];
  brandWebhookId: Scalars['Uuid']['output'];
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idempotencyKey: Scalars['String']['output'];
  statusCode?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export type BrandWebhookEventType = {
  __typename?: 'BrandWebhookEventType';
  brandWebhookId: Scalars['Uuid']['output'];
  eventType: Scalars['String']['output'];
};

export type BrandWebhookUpdateData = {
  description?: InputMaybe<Scalars['String']['input']>;
  endpointUrl?: InputMaybe<Scalars['String']['input']>;
  eventTypes?: InputMaybe<Array<BrandWebhookEmbeddedEventTypesData>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrandYotpoDeveloperIntegration = {
  __typename?: 'BrandYotpoDeveloperIntegration';
  brandYotpoMappings: Array<BrandYotpoMapping>;
  connectionError?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCustomerSyncEnabled: Scalars['Boolean']['output'];
  storeId: Scalars['String']['output'];
  subscriberListId?: Maybe<Scalars['String']['output']>;
};

export type BrandYotpoDeveloperIntegrationEmbeddedBrandYotpoMappingsData = {
  destinationProperty: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Uuid']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  profileProperty: ProfileProperty;
};

export type BrandYotpoDeveloperIntegrationUpdateData = {
  brandYotpoMappings?: InputMaybe<Array<BrandYotpoDeveloperIntegrationEmbeddedBrandYotpoMappingsData>>;
  isCustomerSyncEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  subscriberListId?: InputMaybe<Scalars['String']['input']>;
};

export type BrandYotpoIntegration = {
  __typename?: 'BrandYotpoIntegration';
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  redemptionUrl?: Maybe<Scalars['String']['output']>;
};

export type BrandYotpoMapping = {
  __typename?: 'BrandYotpoMapping';
  brandId: Scalars['Uuid']['output'];
  destinationProperty: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  profileProperty: ProfileProperty;
};

export type BrandYotpoRedemptionOption = {
  __typename?: 'BrandYotpoRedemptionOption';
  brandId: Scalars['Uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastRedemptionAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  pointCost: Scalars['Int']['output'];
  totalRedemptions: Scalars['Int']['output'];
};

export type CachedUrlPreview = {
  __typename?: 'CachedUrlPreview';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  embedHtml?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type Challenge = {
  __typename?: 'Challenge';
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  challengeInputs: Array<ChallengeInput>;
  completionText: Scalars['String']['output'];
  coverImageFile?: Maybe<File>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  entriesCount: Scalars['Int']['output'];
  entryInterval: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  participantId?: Maybe<Scalars['String']['output']>;
  participants: Array<ChallengeParticipant>;
  points?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: ChallengeType;
  updatedAt: Scalars['DateTime']['output'];
  userEntriesCount?: Maybe<Scalars['Int']['output']>;
  userParticipant?: Maybe<ChallengeParticipant>;
};


export type ChallengeParticipantIdArgs = {
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};


export type ChallengeUserEntriesCountArgs = {
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};


export type ChallengeUserParticipantArgs = {
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type ChallengeAnswer = {
  __typename?: 'ChallengeAnswer';
  challengeEntryId?: Maybe<Scalars['Uuid']['output']>;
  challengeInput: ChallengeInput;
  id: Scalars['ID']['output'];
  imageFile?: Maybe<File>;
  participant: ChallengeParticipant;
  text?: Maybe<Scalars['String']['output']>;
  videoFile?: Maybe<File>;
  videoFileId?: Maybe<Scalars['Uuid']['output']>;
};

export type ChallengeCreateData = {
  brandId: Scalars['Uuid']['input'];
  challengeInputs: Array<ChallengeEmbeddedChallengeInputsData>;
  completionText?: InputMaybe<Scalars['String']['input']>;
  coverImageFile?: InputMaybe<FileUploadInput>;
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  entriesCount?: InputMaybe<Scalars['Int']['input']>;
  entryInterval?: InputMaybe<Scalars['Int']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type?: InputMaybe<ChallengeType>;
};

export type ChallengeEmbeddedChallengeInputsData = {
  id?: InputMaybe<Scalars['Uuid']['input']>;
  index: Scalars['Int']['input'];
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  type: ChallengeInputType;
};

export type ChallengeEntry = {
  __typename?: 'ChallengeEntry';
  createdAt: Scalars['DateTime']['output'];
  entryAnswers: Array<ChallengeAnswer>;
  id: Scalars['ID']['output'];
  participant: ChallengeParticipant;
  remindOfNextEntry: Scalars['Boolean']['output'];
};

export type ChallengeEntryEmbeddedEntryAnswersData = {
  challengeInputId: Scalars['Uuid']['input'];
  id?: InputMaybe<Scalars['Uuid']['input']>;
  imageFile?: InputMaybe<FileUploadInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  videoFile?: InputMaybe<FileUploadInput>;
};

export type ChallengeInput = {
  __typename?: 'ChallengeInput';
  challengeAnswers: Array<ChallengeAnswer>;
  challengeId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  optional: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: ChallengeInputType;
};

export type ChallengeInputCreateData = {
  challengeId: Scalars['Uuid']['input'];
  id?: InputMaybe<Scalars['Uuid']['input']>;
  index: Scalars['Int']['input'];
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  type: ChallengeInputType;
};

export type ChallengeInputType =
  | 'FILE_UPLOAD'
  | 'IMAGE_UPLOAD'
  | 'LONG_TEXT'
  | 'MULTI_SELECT'
  | 'SHORT_TEXT'
  | 'VIDEO_UPLOAD';

export type ChallengeInputUpdateData = {
  index?: InputMaybe<Scalars['Int']['input']>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ChallengeParticipant = {
  __typename?: 'ChallengeParticipant';
  challenge: Challenge;
  challengeEntries: Array<ChallengeEntry>;
  challengeId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type ChallengeParticipantCreateData = {
  challengeEntries?: InputMaybe<Array<ChallengeParticipantEmbeddedChallengeEntriesData>>;
  challengeId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type ChallengeParticipantEmbeddedChallengeEntriesData = {
  entryAnswers?: InputMaybe<Array<ChallengeEntryEmbeddedEntryAnswersData>>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
};

export type ChallengeParticipantUpdateData = {
  challengeEntries?: InputMaybe<Array<ChallengeParticipantEmbeddedChallengeEntriesData>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ChallengeType =
  | 'BEFORE_AFTER'
  | 'CUSTOM';

export type ChallengeUpdateData = {
  challengeInputs?: InputMaybe<Array<ChallengeEmbeddedChallengeInputsData>>;
  completionText?: InputMaybe<Scalars['String']['input']>;
  coverImageFile?: InputMaybe<FileUploadInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailSentAt?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for checkEmailVerification mutation */
export type CheckEmailVerificationInput = {
  userId: Scalars['Uuid']['input'];
};

/** Payload type for checkEmailVerification mutation */
export type CheckEmailVerificationPayload = {
  __typename?: 'CheckEmailVerificationPayload';
  user: User;
};

/** Input type for checkPhoneVerification mutation */
export type CheckPhoneVerificationInput = {
  code: Scalars['String']['input'];
  verificationId: Scalars['Uuid']['input'];
};

/** Payload type for checkPhoneVerification mutation */
export type CheckPhoneVerificationPayload = {
  __typename?: 'CheckPhoneVerificationPayload';
  isCodeValid: Scalars['Boolean']['output'];
  user: User;
};

/** Input type for commentOnPost mutation */
export type CommentOnPostInput = {
  attachments?: InputMaybe<Array<PostCommentEmbeddedAttachmentsData>>;
  contentHtml: Scalars['String']['input'];
  embeddedUrl?: InputMaybe<Scalars['String']['input']>;
  postId: Scalars['Uuid']['input'];
  replyToCommentId?: InputMaybe<Scalars['Uuid']['input']>;
  /** @deprecated No longer needed */
  tzOffset?: InputMaybe<Scalars['Int']['input']>;
};

/** Payload type for commentOnPost mutation */
export type CommentOnPostPayload = {
  __typename?: 'CommentOnPostPayload';
  comment: PostComment;
  post: Post;
};

/** Input type for connectBrandInveterateIntegration mutation */
export type ConnectBrandInveterateIntegrationInput = {
  apiKey: Scalars['String']['input'];
  brandId: Scalars['Uuid']['input'];
};

/** Payload type for connectBrandInveterateIntegration mutation */
export type ConnectBrandInveterateIntegrationPayload = {
  __typename?: 'ConnectBrandInveterateIntegrationPayload';
  brand: Brand;
};

/** Input type for connectBrandYotpoIntegration mutation */
export type ConnectBrandYotpoIntegrationInput = {
  apiKey: Scalars['String']['input'];
  brandId: Scalars['Uuid']['input'];
  guid: Scalars['String']['input'];
};

/** Payload type for connectBrandYotpoIntegration mutation */
export type ConnectBrandYotpoIntegrationPayload = {
  __typename?: 'ConnectBrandYotpoIntegrationPayload';
  brand: Brand;
};

export type ContentReport = {
  __typename?: 'ContentReport';
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  challengeAnswer?: Maybe<ChallengeAnswer>;
  challengeAnswerId?: Maybe<Scalars['Uuid']['output']>;
  challengeParticipant?: Maybe<ChallengeParticipant>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  participantId?: Maybe<Scalars['Uuid']['output']>;
  post?: Maybe<Post>;
  postComment?: Maybe<PostComment>;
  postCommentId?: Maybe<Scalars['Uuid']['output']>;
  postId?: Maybe<Scalars['Uuid']['output']>;
  reasonOther?: Maybe<Scalars['String']['output']>;
  reportType: ReportType;
  reporter?: Maybe<User>;
  reporterId?: Maybe<Scalars['Uuid']['output']>;
  shelf?: Maybe<Shelf>;
  shelfProduct?: Maybe<ShelfProduct>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Uuid']['output']>;
};

/** Input type for createBannedWord mutation */
export type CreateBannedWordInput = {
  data: BannedWordCreateData;
};

/** Payload type for createBannedWord mutation */
export type CreateBannedWordPayload = {
  __typename?: 'CreateBannedWordPayload';
  bannedWord: BannedWord;
};

/** Input type for createBrandApiKey mutation */
export type CreateBrandApiKeyInput = {
  data: BrandApiKeyCreateData;
};

/** Payload type for createBrandApiKey mutation */
export type CreateBrandApiKeyPayload = {
  __typename?: 'CreateBrandApiKeyPayload';
  brandApiKey: BrandApiKey;
  key: Scalars['String']['output'];
};

/** Input type for createBrandEvent mutation */
export type CreateBrandEventInput = {
  data: BrandEventCreateData;
};

/** Payload type for createBrandEvent mutation */
export type CreateBrandEventPayload = {
  __typename?: 'CreateBrandEventPayload';
  brandEvent: BrandEvent;
};

/** Input type for createBrand mutation */
export type CreateBrandInput = {
  data: BrandCreateData;
};

/** Input type for createBrandKlaviyoIntegration mutation */
export type CreateBrandKlaviyoIntegrationInput = {
  data: BrandKlaviyoIntegrationCreateData;
};

/** Payload type for createBrandKlaviyoIntegration mutation */
export type CreateBrandKlaviyoIntegrationPayload = {
  __typename?: 'CreateBrandKlaviyoIntegrationPayload';
  brandKlaviyoIntegration: BrandKlaviyoIntegration;
};

/** Input type for createBrandLink mutation */
export type CreateBrandLinkInput = {
  data: BrandLinkCreateData;
};

/** Payload type for createBrandLink mutation */
export type CreateBrandLinkPayload = {
  __typename?: 'CreateBrandLinkPayload';
  brandLink: BrandLink;
};

/** Input type for createBrandMemberBadge mutation */
export type CreateBrandMemberBadgeInput = {
  data: BrandMemberBadgeCreateData;
};

/** Payload type for createBrandMemberBadge mutation */
export type CreateBrandMemberBadgePayload = {
  __typename?: 'CreateBrandMemberBadgePayload';
  brandMemberBadge: BrandMemberBadge;
};

/** Input type for createBrandMember mutation */
export type CreateBrandMemberInput = {
  data: BrandMemberCreateData;
};

/** Input type for createBrandMemberLabel mutation */
export type CreateBrandMemberLabelInput = {
  data: BrandMemberLabelCreateData;
};

/** Input type for createBrandMemberLabelOption mutation */
export type CreateBrandMemberLabelOptionInput = {
  data: BrandMemberLabelOptionCreateData;
};

/** Payload type for createBrandMemberLabelOption mutation */
export type CreateBrandMemberLabelOptionPayload = {
  __typename?: 'CreateBrandMemberLabelOptionPayload';
  brandMemberLabelOption: BrandMemberLabelOption;
};

/** Payload type for createBrandMemberLabel mutation */
export type CreateBrandMemberLabelPayload = {
  __typename?: 'CreateBrandMemberLabelPayload';
  brandMemberLabel: BrandMemberLabel;
};

/** Input type for createBrandMemberNotificationSetting mutation */
export type CreateBrandMemberNotificationSettingInput = {
  data: BrandMemberNotificationSettingCreateData;
};

/** Payload type for createBrandMemberNotificationSetting mutation */
export type CreateBrandMemberNotificationSettingPayload = {
  __typename?: 'CreateBrandMemberNotificationSettingPayload';
  brandMemberNotificationSetting: BrandMemberNotificationSetting;
};

/** Payload type for createBrandMember mutation */
export type CreateBrandMemberPayload = {
  __typename?: 'CreateBrandMemberPayload';
  brandMember: BrandMember;
};

/** Payload type for createBrand mutation */
export type CreateBrandPayload = {
  __typename?: 'CreateBrandPayload';
  brand: Brand;
};

/** Input type for createBrandWebhook mutation */
export type CreateBrandWebhookInput = {
  data: BrandWebhookCreateData;
};

/** Payload type for createBrandWebhook mutation */
export type CreateBrandWebhookPayload = {
  __typename?: 'CreateBrandWebhookPayload';
  brandWebhook: BrandWebhook;
};

/** Payload type for createBullBoardAuthCode mutation */
export type CreateBullBoardAuthCodePayload = {
  __typename?: 'CreateBullBoardAuthCodePayload';
  code: Scalars['String']['output'];
};

/** Input type for createChallenge mutation */
export type CreateChallengeInput = {
  data: ChallengeCreateData;
};

/** Input type for createChallengeInput mutation */
export type CreateChallengeInputInput = {
  data: ChallengeInputCreateData;
};

/** Payload type for createChallengeInput mutation */
export type CreateChallengeInputPayload = {
  __typename?: 'CreateChallengeInputPayload';
  challengeInput: ChallengeInput;
};

/** Input type for createChallengeParticipant mutation */
export type CreateChallengeParticipantInput = {
  data: ChallengeParticipantCreateData;
};

/** Payload type for createChallengeParticipant mutation */
export type CreateChallengeParticipantPayload = {
  __typename?: 'CreateChallengeParticipantPayload';
  challengeParticipant: ChallengeParticipant;
};

/** Payload type for createChallenge mutation */
export type CreateChallengePayload = {
  __typename?: 'CreateChallengePayload';
  challenge: Challenge;
};

/** Input type for createCustomTraitGroup mutation */
export type CreateCustomTraitGroupInput = {
  data: CustomTraitGroupCreateData;
};

/** Payload type for createCustomTraitGroup mutation */
export type CreateCustomTraitGroupPayload = {
  __typename?: 'CreateCustomTraitGroupPayload';
  customTraitGroup: CustomTraitGroup;
};

/** Input type for createEventAttendee mutation */
export type CreateEventAttendeeInput = {
  data: EventAttendeeCreateData;
};

/** Payload type for createEventAttendee mutation */
export type CreateEventAttendeePayload = {
  __typename?: 'CreateEventAttendeePayload';
  eventAttendee: EventAttendee;
};

/** Input type for createFaq mutation */
export type CreateFaqInput = {
  data: FaqCreateData;
};

/** Payload type for createFaq mutation */
export type CreateFaqPayload = {
  __typename?: 'CreateFaqPayload';
  faq: Faq;
};

/** Input type for createLoyaltyAction mutation */
export type CreateLoyaltyActionInput = {
  data: LoyaltyActionCreateData;
};

/** Payload type for createLoyaltyAction mutation */
export type CreateLoyaltyActionPayload = {
  __typename?: 'CreateLoyaltyActionPayload';
  loyaltyAction: LoyaltyAction;
};

/** Input type for createLoyaltyDiscount mutation */
export type CreateLoyaltyDiscountInput = {
  data: LoyaltyDiscountCreateData;
};

/** Payload type for createLoyaltyDiscount mutation */
export type CreateLoyaltyDiscountPayload = {
  __typename?: 'CreateLoyaltyDiscountPayload';
  loyaltyDiscount: LoyaltyDiscount;
};

/** Input type for createMemberGroup mutation */
export type CreateMemberGroupInput = {
  data: MemberGroupCreateData;
};

/** Payload type for createMemberGroup mutation */
export type CreateMemberGroupPayload = {
  __typename?: 'CreateMemberGroupPayload';
  memberGroup: MemberGroup;
};

/** Input type for createOnboarding mutation */
export type CreateOnboardingInput = {
  data: OnboardingCreateData;
};

/** Payload type for createOnboarding mutation */
export type CreateOnboardingPayload = {
  __typename?: 'CreateOnboardingPayload';
  onboarding: Onboarding;
};

/** Input type for createPost mutation */
export type CreatePostInput = {
  data: PostCreateData;
};

/** Payload type for createPost mutation */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  post: Post;
};

/** Input type for createPostPollVote mutation */
export type CreatePostPollVoteInput = {
  pollChoiceId: Scalars['Uuid']['input'];
  pollId: Scalars['Uuid']['input'];
};

/** Payload type for createPostPollVote mutation */
export type CreatePostPollVotePayload = {
  __typename?: 'CreatePostPollVotePayload';
  postPoll: PostPoll;
};

/** Input type for createPostPrompt mutation */
export type CreatePostPromptInput = {
  data: PostPromptCreateData;
};

/** Payload type for createPostPrompt mutation */
export type CreatePostPromptPayload = {
  __typename?: 'CreatePostPromptPayload';
  postPrompt: PostPrompt;
};

/** Input type for createPostTagOption mutation */
export type CreatePostTagOptionInput = {
  data: PostTagOptionCreateData;
};

/** Payload type for createPostTagOption mutation */
export type CreatePostTagOptionPayload = {
  __typename?: 'CreatePostTagOptionPayload';
  postTagOption: PostTagOption;
};

/** Input type for createPresignedDownloadUrl mutation */
export type CreatePresignedDownloadUrlInput = {
  fileId: Scalars['Uuid']['input'];
};

/** Payload type for createPresignedDownloadUrl mutation */
export type CreatePresignedDownloadUrlPayload = {
  __typename?: 'CreatePresignedDownloadUrlPayload';
  url: Scalars['String']['output'];
};

/** Input type for createPresignedUploadUrl mutation */
export type CreatePresignedUploadUrlInput = {
  category: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
};

/** Payload type for createPresignedUploadUrl mutation */
export type CreatePresignedUploadUrlPayload = {
  __typename?: 'CreatePresignedUploadUrlPayload';
  fields?: Maybe<Array<PresignedUrlField>>;
  file: File;
  method: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Input type for createRoomMember mutation */
export type CreateRoomMemberInput = {
  data: RoomMemberCreateData;
};

/** Payload type for createRoomMember mutation */
export type CreateRoomMemberPayload = {
  __typename?: 'CreateRoomMemberPayload';
  roomMember: RoomMember;
};

/** Input type for createRoomOption mutation */
export type CreateRoomOptionInput = {
  data: RoomOptionCreateData;
};

/** Payload type for createRoomOption mutation */
export type CreateRoomOptionPayload = {
  __typename?: 'CreateRoomOptionPayload';
  roomOption: RoomOption;
};

/** Input type for createShelf mutation */
export type CreateShelfInput = {
  data: ShelfCreateData;
};

/** Payload type for createShelf mutation */
export type CreateShelfPayload = {
  __typename?: 'CreateShelfPayload';
  shelf: Shelf;
};

/** Input type for createShelfProduct mutation */
export type CreateShelfProductInput = {
  data: ShelfProductCreateData;
};

/** Payload type for createShelfProduct mutation */
export type CreateShelfProductPayload = {
  __typename?: 'CreateShelfProductPayload';
  shelfProduct: ShelfProduct;
};

/** Input type for createShelfWithProduct mutation */
export type CreateShelfWithProductInput = {
  data: ShelfWithProductCreateData;
};

/** Payload type for createShelfWithProduct mutation */
export type CreateShelfWithProductPayload = {
  __typename?: 'CreateShelfWithProductPayload';
  shelfProduct: ShelfProduct;
};

/** Input type for createTrialBrand mutation */
export type CreateTrialBrandInput = {
  name: Scalars['String']['input'];
  shopUrl?: InputMaybe<Scalars['String']['input']>;
  subdomain: Scalars['String']['input'];
};

/** Payload type for createTrialBrand mutation */
export type CreateTrialBrandPayload = {
  __typename?: 'CreateTrialBrandPayload';
  brand: Brand;
};

/** Input type for createUser mutation */
export type CreateUserInput = {
  data: UserCreateData;
};

/** Payload type for createUser mutation */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user: User;
};

/** Input type for createYotpoDeveloperInstallationLink mutation */
export type CreateYotpoDeveloperInstallationLinkInput = {
  brandId: Scalars['Uuid']['input'];
};

/** Payload type for createYotpoDeveloperInstallationLink mutation */
export type CreateYotpoDeveloperInstallationLinkPayload = {
  __typename?: 'CreateYotpoDeveloperInstallationLinkPayload';
  link: Scalars['String']['output'];
};

export type CustomTrait = {
  __typename?: 'CustomTrait';
  group: CustomTraitGroup;
  groupId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  responses: Array<CustomTraitResponse>;
  selectOptions: Array<CustomTraitOption>;
  syncWithKlayvio: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: CustomTraitType;
};

export type CustomTraitBulkUpdateInput = {
  data: CustomTraitUpdateData;
  id: Scalars['Uuid']['input'];
};

export type CustomTraitEmbeddedSelectOptionsData = {
  id?: InputMaybe<Scalars['Uuid']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

export type CustomTraitGroup = {
  __typename?: 'CustomTraitGroup';
  brand: Brand;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  traits: Array<CustomTrait>;
};

export type CustomTraitGroupCreateData = {
  brandId: Scalars['Uuid']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  traits?: InputMaybe<Array<CustomTraitGroupEmbeddedTraitsData>>;
};

export type CustomTraitGroupEmbeddedTraitsData = {
  id?: InputMaybe<Scalars['Uuid']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  selectOptions?: InputMaybe<Array<CustomTraitEmbeddedSelectOptionsData>>;
  title: Scalars['String']['input'];
  type: CustomTraitType;
};

export type CustomTraitGroupUpdateData = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  traits?: InputMaybe<Array<CustomTraitGroupEmbeddedTraitsData>>;
};

export type CustomTraitOption = {
  __typename?: 'CustomTraitOption';
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  response: Array<CustomTraitResponse>;
  text: Scalars['String']['output'];
  trait: CustomTrait;
};

export type CustomTraitResponse = {
  __typename?: 'CustomTraitResponse';
  customTrait: CustomTrait;
  selectedOption?: Maybe<CustomTraitOption>;
  text?: Maybe<Scalars['String']['output']>;
  traitId: Scalars['Uuid']['output'];
  traitOptionId?: Maybe<Scalars['Uuid']['output']>;
  userId: Scalars['Uuid']['output'];
};

export type CustomTraitType =
  | 'SELECT'
  | 'TEXT_INPUT';

export type CustomTraitUpdateData = {
  index?: InputMaybe<Scalars['Int']['input']>;
  syncWithKlayvio?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for deleteBannedWord mutation */
export type DeleteBannedWordInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBannedWord mutation */
export type DeleteBannedWordPayload = {
  __typename?: 'DeleteBannedWordPayload';
  bannedWord: BannedWord;
};

/** Input type for deleteBrandApiKey mutation */
export type DeleteBrandApiKeyInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBrandApiKey mutation */
export type DeleteBrandApiKeyPayload = {
  __typename?: 'DeleteBrandApiKeyPayload';
  brandApiKey: BrandApiKey;
};

/** Input type for deleteBrandEvent mutation */
export type DeleteBrandEventInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBrandEvent mutation */
export type DeleteBrandEventPayload = {
  __typename?: 'DeleteBrandEventPayload';
  brandEvent: BrandEvent;
};

/** Input type for deleteBrand mutation */
export type DeleteBrandInput = {
  id: Scalars['Uuid']['input'];
};

/** Input type for deleteBrandKlaviyoIntegration mutation */
export type DeleteBrandKlaviyoIntegrationInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBrandKlaviyoIntegration mutation */
export type DeleteBrandKlaviyoIntegrationPayload = {
  __typename?: 'DeleteBrandKlaviyoIntegrationPayload';
  brandKlaviyoIntegration: BrandKlaviyoIntegration;
};

/** Input type for deleteBrandLink mutation */
export type DeleteBrandLinkInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBrandLink mutation */
export type DeleteBrandLinkPayload = {
  __typename?: 'DeleteBrandLinkPayload';
  brandLink: BrandLink;
};

/** Input type for deleteBrandMemberBadge mutation */
export type DeleteBrandMemberBadgeInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBrandMemberBadge mutation */
export type DeleteBrandMemberBadgePayload = {
  __typename?: 'DeleteBrandMemberBadgePayload';
  brandMemberBadge: BrandMemberBadge;
};

/** Input type for deleteBrandMember mutation */
export type DeleteBrandMemberInput = {
  id: BrandMemberPrimaryKey;
};

/** Input type for deleteBrandMemberLabel mutation */
export type DeleteBrandMemberLabelInput = {
  id: BrandMemberLabelPrimaryKey;
};

/** Input type for deleteBrandMemberLabelOption mutation */
export type DeleteBrandMemberLabelOptionInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBrandMemberLabelOption mutation */
export type DeleteBrandMemberLabelOptionPayload = {
  __typename?: 'DeleteBrandMemberLabelOptionPayload';
  brandMemberLabelOption: BrandMemberLabelOption;
};

/** Payload type for deleteBrandMemberLabel mutation */
export type DeleteBrandMemberLabelPayload = {
  __typename?: 'DeleteBrandMemberLabelPayload';
  brandMemberLabel: BrandMemberLabel;
};

/** Input type for deleteBrandMemberNotificationSetting mutation */
export type DeleteBrandMemberNotificationSettingInput = {
  id: BrandMemberNotificationSettingPrimaryKey;
};

/** Payload type for deleteBrandMemberNotificationSetting mutation */
export type DeleteBrandMemberNotificationSettingPayload = {
  __typename?: 'DeleteBrandMemberNotificationSettingPayload';
  brandMemberNotificationSetting: BrandMemberNotificationSetting;
};

/** Payload type for deleteBrandMember mutation */
export type DeleteBrandMemberPayload = {
  __typename?: 'DeleteBrandMemberPayload';
  brandMember: BrandMember;
};

/** Payload type for deleteBrand mutation */
export type DeleteBrandPayload = {
  __typename?: 'DeleteBrandPayload';
  brand: Brand;
};

/** Input type for deleteBrandWebhook mutation */
export type DeleteBrandWebhookInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteBrandWebhook mutation */
export type DeleteBrandWebhookPayload = {
  __typename?: 'DeleteBrandWebhookPayload';
  brandWebhook: BrandWebhook;
};

/** Input type for deleteChallenge mutation */
export type DeleteChallengeInput = {
  id: Scalars['Uuid']['input'];
};

/** Input type for deleteChallengeInput mutation */
export type DeleteChallengeInputInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteChallengeInput mutation */
export type DeleteChallengeInputPayload = {
  __typename?: 'DeleteChallengeInputPayload';
  challengeInput: ChallengeInput;
};

/** Input type for deleteChallengeParticipant mutation */
export type DeleteChallengeParticipantInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteChallengeParticipant mutation */
export type DeleteChallengeParticipantPayload = {
  __typename?: 'DeleteChallengeParticipantPayload';
  challengeParticipant: ChallengeParticipant;
};

/** Payload type for deleteChallenge mutation */
export type DeleteChallengePayload = {
  __typename?: 'DeleteChallengePayload';
  challenge: Challenge;
};

/** Input type for deleteContentReport mutation */
export type DeleteContentReportInput = {
  contentReportId: Scalars['Uuid']['input'];
};

/** Payload type for deleteContentReport mutation */
export type DeleteContentReportPayload = {
  __typename?: 'DeleteContentReportPayload';
  success: Scalars['Boolean']['output'];
};

/** Input type for deleteCustomTraitGroup mutation */
export type DeleteCustomTraitGroupInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteCustomTraitGroup mutation */
export type DeleteCustomTraitGroupPayload = {
  __typename?: 'DeleteCustomTraitGroupPayload';
  customTraitGroup: CustomTraitGroup;
};

/** Input type for deleteEventAttendee mutation */
export type DeleteEventAttendeeInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteEventAttendee mutation */
export type DeleteEventAttendeePayload = {
  __typename?: 'DeleteEventAttendeePayload';
  eventAttendee: EventAttendee;
};

/** Input type for deleteFaq mutation */
export type DeleteFaqInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteFaq mutation */
export type DeleteFaqPayload = {
  __typename?: 'DeleteFaqPayload';
  faq: Faq;
};

/** Input type for deleteLoyaltyAction mutation */
export type DeleteLoyaltyActionInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteLoyaltyAction mutation */
export type DeleteLoyaltyActionPayload = {
  __typename?: 'DeleteLoyaltyActionPayload';
  loyaltyAction: LoyaltyAction;
};

/** Input type for deleteLoyaltyDiscount mutation */
export type DeleteLoyaltyDiscountInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteLoyaltyDiscount mutation */
export type DeleteLoyaltyDiscountPayload = {
  __typename?: 'DeleteLoyaltyDiscountPayload';
  loyaltyDiscount: LoyaltyDiscount;
};

/** Input type for deleteMemberGroup mutation */
export type DeleteMemberGroupInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteMemberGroup mutation */
export type DeleteMemberGroupPayload = {
  __typename?: 'DeleteMemberGroupPayload';
  memberGroup: MemberGroup;
};

/** Input type for deleteOnboarding mutation */
export type DeleteOnboardingInput = {
  id: OnboardingPrimaryKey;
};

/** Payload type for deleteOnboarding mutation */
export type DeleteOnboardingPayload = {
  __typename?: 'DeleteOnboardingPayload';
  onboarding: Onboarding;
};

/** Input type for deletePost mutation */
export type DeletePostInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deletePost mutation */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  post: Post;
};

/** Input type for deletePostPrompt mutation */
export type DeletePostPromptInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deletePostPrompt mutation */
export type DeletePostPromptPayload = {
  __typename?: 'DeletePostPromptPayload';
  postPrompt: PostPrompt;
};

/** Input type for deletePostTagOption mutation */
export type DeletePostTagOptionInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deletePostTagOption mutation */
export type DeletePostTagOptionPayload = {
  __typename?: 'DeletePostTagOptionPayload';
  postTagOption: PostTagOption;
};

/** Input type for deleteRoomMember mutation */
export type DeleteRoomMemberInput = {
  id: RoomMemberPrimaryKey;
};

/** Payload type for deleteRoomMember mutation */
export type DeleteRoomMemberPayload = {
  __typename?: 'DeleteRoomMemberPayload';
  roomOptionId: Scalars['Uuid']['output'];
  userId: Scalars['Uuid']['output'];
};

/** Input type for deleteRoomOption mutation */
export type DeleteRoomOptionInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteRoomOption mutation */
export type DeleteRoomOptionPayload = {
  __typename?: 'DeleteRoomOptionPayload';
  roomOption: RoomOption;
};

/** Input type for deleteShelf mutation */
export type DeleteShelfInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteShelf mutation */
export type DeleteShelfPayload = {
  __typename?: 'DeleteShelfPayload';
  shelf: Shelf;
};

/** Input type for deleteShelfProduct mutation */
export type DeleteShelfProductInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteShelfProduct mutation */
export type DeleteShelfProductPayload = {
  __typename?: 'DeleteShelfProductPayload';
  shelfProduct: ShelfProduct;
};

/** Input type for deleteUser mutation */
export type DeleteUserInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for deleteUser mutation */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  user: User;
};

/** Input type for disconnectBrandInveterateIntegration mutation */
export type DisconnectBrandInveterateIntegrationInput = {
  brandId: Scalars['Uuid']['input'];
};

/** Payload type for disconnectBrandInveterateIntegration mutation */
export type DisconnectBrandInveterateIntegrationPayload = {
  __typename?: 'DisconnectBrandInveterateIntegrationPayload';
  brand: Brand;
};

/** Input type for disconnectBrandShopifyApiKey mutation */
export type DisconnectBrandShopifyApiKeyInput = {
  brandId: Scalars['Uuid']['input'];
};

/** Payload type for disconnectBrandShopifyApiKey mutation */
export type DisconnectBrandShopifyApiKeyPayload = {
  __typename?: 'DisconnectBrandShopifyApiKeyPayload';
  brand: Brand;
};

/** Input type for disconnectBrandYotpoIntegration mutation */
export type DisconnectBrandYotpoIntegrationInput = {
  brandId: Scalars['Uuid']['input'];
};

/** Payload type for disconnectBrandYotpoIntegration mutation */
export type DisconnectBrandYotpoIntegrationPayload = {
  __typename?: 'DisconnectBrandYotpoIntegrationPayload';
  brand: Brand;
};

export type EventAttendee = {
  __typename?: 'EventAttendee';
  event: BrandEvent;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type EventAttendeeCreateData = {
  eventId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type EventAttendeeUpdateData = {
  eventId?: InputMaybe<Scalars['Uuid']['input']>;
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type Faq = {
  __typename?: 'Faq';
  answer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
};

export type FaqCreateData = {
  answer: Scalars['String']['input'];
  brandId: Scalars['Uuid']['input'];
  question: Scalars['String']['input'];
};

export type FaqUpdateData = {
  answer?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
};

export type FeedFilterBy =
  | 'ALL'
  | 'CHALLENGES'
  | 'COMMENTS'
  | 'NO_ADMIN_RESPONSE'
  | 'NO_RESPONSE'
  | 'POSTS'
  | 'SHELVES';

export type FeedItem = {
  __typename?: 'FeedItem';
  brandEvent?: Maybe<BrandEvent>;
  challenge?: Maybe<Challenge>;
  challengeSubmission?: Maybe<ChallengeEntry>;
  createdAt: Scalars['DateTime']['output'];
  cursor?: Maybe<Scalars['String']['output']>;
  post?: Maybe<Post>;
  postComment?: Maybe<PostComment>;
  shelfUpdate?: Maybe<ShelfUpdate>;
  type: Scalars['String']['output'];
};

export type FeedItemConnection = {
  __typename?: 'FeedItemConnection';
  edges: Array<FeedItemEdge>;
  pageInfo: PageInfo;
};

export type FeedItemEdge = {
  __typename?: 'FeedItemEdge';
  cursor: Scalars['String']['output'];
  node: FeedItem;
};

export type File = {
  __typename?: 'File';
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  /** URL of the file where it is publicly hosted. Returns null if it is not publicly available. */
  hostedUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReady: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  playbackId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FileUploadInput = {
  id: Scalars['Uuid']['input'];
  /** Discarded but useful for forms */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for generateBrandShopifyApiKey mutation */
export type GenerateBrandShopifyApiKeyInput = {
  brandId: Scalars['Uuid']['input'];
  shouldRegenerate?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Payload type for generateBrandShopifyApiKey mutation */
export type GenerateBrandShopifyApiKeyPayload = {
  __typename?: 'GenerateBrandShopifyApiKeyPayload';
  brand: Brand;
};

export type GetMuxUploadUrlInput = {
  category: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
};

/** Input type for handleYotpoDeveloperAuthCallback mutation */
export type HandleYotpoDeveloperAuthCallbackInput = {
  appKey: Scalars['String']['input'];
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

/** Payload type for handleYotpoDeveloperAuthCallback mutation */
export type HandleYotpoDeveloperAuthCallbackPayload = {
  __typename?: 'HandleYotpoDeveloperAuthCallbackPayload';
  brandId: Scalars['String']['output'];
};

export type HeaderButtonIconType =
  | 'BOOKMARK'
  | 'CALENDAR'
  | 'LOCATION'
  | 'MOBILE'
  | 'NOTES'
  | 'SHOP'
  | 'SHOPIFY';

export type HighlightPostData = {
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input type for highlightPost mutation */
export type HighlightPostInput = {
  data: HighlightPostData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for highlightPost mutation */
export type HighlightPostPayload = {
  __typename?: 'HighlightPostPayload';
  post: Post;
};

/** Input type for joinBrand mutation */
export type JoinBrandInput = {
  brandId: Scalars['Uuid']['input'];
  optInToEmailMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  referral?: InputMaybe<Scalars['String']['input']>;
};

/** Payload type for joinBrand mutation */
export type JoinBrandPayload = {
  __typename?: 'JoinBrandPayload';
  user: User;
};

export type KlaviyoList = {
  __typename?: 'KlaviyoList';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type KlaviyoProperty =
  | 'BIRTHDAY'
  | 'EMAIL'
  | 'EYE_COLOR'
  | 'FIRST_NAME'
  | 'HAIR_COLOR'
  | 'HAIR_CONCERN'
  | 'HAIR_LENGTH'
  | 'HAIR_TEXTURE'
  | 'JOINED_DATE'
  | 'LAST_NAME'
  | 'LOCATION'
  | 'SKIN_TONE'
  | 'SKIN_TYPE';

export type LoyaltyAction = {
  __typename?: 'LoyaltyAction';
  brandId: Scalars['Uuid']['output'];
  earnPeriod?: Maybe<LoyaltyActionPeriod>;
  earnRate?: Maybe<Scalars['Int']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  points: Scalars['Int']['output'];
  type: LoyaltyActionType;
};

export type LoyaltyActionCreateData = {
  brandId: Scalars['Uuid']['input'];
  earnPeriod?: InputMaybe<LoyaltyActionPeriod>;
  earnRate?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  points: Scalars['Int']['input'];
  type: LoyaltyActionType;
};

export type LoyaltyActionPeriod =
  | 'DAY'
  | 'HOUR'
  | 'WEEK';

export type LoyaltyActionType =
  | 'ACCOUNT_CREATE'
  | 'CHALLENGE_COMPLETE'
  | 'POST_CREATE'
  | 'POST_REPLY'
  | 'PROFILE_COMPLETE'
  | 'REFERRAL_SIGNUP'
  | 'SOCIAL_COMPLETE'
  | 'VISIT_DAILY';

export type LoyaltyActionUpdateData = {
  earnPeriod?: InputMaybe<LoyaltyActionPeriod>;
  earnRate?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
};

export type LoyaltyDiscount = {
  __typename?: 'LoyaltyDiscount';
  brandId: Scalars['Uuid']['output'];
  discountPercent: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  maxCountPerMember?: Maybe<Scalars['Int']['output']>;
  pointsCost: Scalars['Int']['output'];
  previousRedemptions?: Maybe<Array<LoyaltyRedeemedDiscounts>>;
  product?: Maybe<BrandProduct>;
  productId?: Maybe<Scalars['Uuid']['output']>;
};


export type LoyaltyDiscountPreviousRedemptionsArgs = {
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type LoyaltyDiscountCreateData = {
  brandId: Scalars['Uuid']['input'];
  discountPercent: Scalars['Int']['input'];
  maxCountPerMember?: InputMaybe<Scalars['Int']['input']>;
  pointsCost: Scalars['Int']['input'];
  productId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type LoyaltyLog = {
  __typename?: 'LoyaltyLog';
  actionType?: Maybe<LoyaltyActionType>;
  actionUrl?: Maybe<Scalars['String']['output']>;
  brandId: Scalars['Uuid']['output'];
  challengeId?: Maybe<Scalars['Uuid']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  loyaltyDiscount?: Maybe<LoyaltyDiscount>;
  loyaltyDiscountId?: Maybe<Scalars['Uuid']['output']>;
  points: Scalars['Int']['output'];
  postId?: Maybe<Scalars['Uuid']['output']>;
  redeemType?: Maybe<LoyaltyRedeemType>;
  userId: Scalars['Uuid']['output'];
};

export type LoyaltyRedeemType =
  | 'REDEEM_DISCOUNT'
  | 'REDEEM_EVENT';

export type LoyaltyRedeemedDiscounts = {
  __typename?: 'LoyaltyRedeemedDiscounts';
  createdAt: Scalars['DateTime']['output'];
  discountCode: Scalars['String']['output'];
};

/** Input type for markAllUserNotificationsAsRead mutation */
export type MarkAllUserNotificationsAsReadInput = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

/** Payload type for markAllUserNotificationsAsRead mutation */
export type MarkAllUserNotificationsAsReadPayload = {
  __typename?: 'MarkAllUserNotificationsAsReadPayload';
  success: Scalars['Boolean']['output'];
};

export type MemberGroup = {
  __typename?: 'MemberGroup';
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  brandMemberBadge?: Maybe<BrandMemberBadge>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  memberCount: Scalars['Int']['output'];
  memberGroupMembers: Array<MemberGroupMember>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MemberGroupCreateData = {
  brandId: Scalars['Uuid']['input'];
  brandMemberBadge?: InputMaybe<MemberGroupEmbeddedBrandMemberBadgeData>;
  name: Scalars['String']['input'];
};

export type MemberGroupEmbeddedBrandMemberBadgeData = {
  backgroundColor: Scalars['String']['input'];
  foregroundColor: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Uuid']['input']>;
  name: Scalars['String']['input'];
};

export type MemberGroupMember = {
  __typename?: 'MemberGroupMember';
  brandMember: BrandMember;
  memberGroup: MemberGroup;
  memberGroupId: Scalars['Uuid']['output'];
  user: User;
  userId: Scalars['Uuid']['output'];
};

export type MemberGroupUpdateData = {
  brandMemberBadge?: InputMaybe<MemberGroupEmbeddedBrandMemberBadgeData>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MemberSortOption =
  | 'NEWEST'
  | 'OLDEST'
  | 'TOP_CONTRIBUTORS';

export type MembersFilter = {
  favoriteShoeTypeId?: InputMaybe<Scalars['Uuid']['input']>;
  hairColorId?: InputMaybe<Scalars['Uuid']['input']>;
  hairLength?: InputMaybe<UserHairLength>;
  hairTexture?: InputMaybe<UserHairTexture>;
  hasBunions?: InputMaybe<Scalars['Boolean']['input']>;
  selectedCustomOptionIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  shoeSizeId?: InputMaybe<Scalars['Uuid']['input']>;
  shoeWidth?: InputMaybe<UserShoeWidth>;
  skinToneCategory?: InputMaybe<UserSkinTone>;
  skinType?: InputMaybe<UserSkinType>;
  topHairConcern?: InputMaybe<UserTopHairConcern>;
  topSkinConcern?: InputMaybe<UserTopSkinConcern>;
};

export type MembersOrderBy =
  | 'NEWEST'
  | 'OLDEST'
  | 'TOP_CONTRIBUTORS';

export type Mutation = {
  __typename?: 'Mutation';
  approvePost: ApprovePostPayload;
  banBrandMember: BanBrandMemberPayload;
  checkEmailVerification: CheckEmailVerificationPayload;
  checkPhoneVerification: CheckPhoneVerificationPayload;
  commentOnPost: CommentOnPostPayload;
  connectBrandInveterateIntegration: ConnectBrandInveterateIntegrationPayload;
  /** Connect a brand to Yotpo's loyalty program. Note: Use BrandYotpoDeveloperIntegration to connect to Yotpo's developer API for SMS and other applications */
  connectBrandYotpoIntegration: ConnectBrandYotpoIntegrationPayload;
  createBannedWord: CreateBannedWordPayload;
  createBrand: CreateBrandPayload;
  createBrandApiKey: CreateBrandApiKeyPayload;
  createBrandEvent: CreateBrandEventPayload;
  createBrandKlaviyoIntegration: CreateBrandKlaviyoIntegrationPayload;
  createBrandLink: CreateBrandLinkPayload;
  createBrandMember: CreateBrandMemberPayload;
  createBrandMemberBadge: CreateBrandMemberBadgePayload;
  createBrandMemberLabel: CreateBrandMemberLabelPayload;
  createBrandMemberLabelOption: CreateBrandMemberLabelOptionPayload;
  createBrandMemberNotificationSetting: CreateBrandMemberNotificationSettingPayload;
  createBrandWebhook: CreateBrandWebhookPayload;
  createBullBoardAuthCode: CreateBullBoardAuthCodePayload;
  createChallenge: CreateChallengePayload;
  createChallengeInput: CreateChallengeInputPayload;
  createChallengeParticipant: CreateChallengeParticipantPayload;
  createCustomTraitGroup: CreateCustomTraitGroupPayload;
  createEventAttendee: CreateEventAttendeePayload;
  createFaq: CreateFaqPayload;
  createLoyaltyAction: CreateLoyaltyActionPayload;
  createLoyaltyDiscount: CreateLoyaltyDiscountPayload;
  createMemberGroup: CreateMemberGroupPayload;
  createOnboarding: CreateOnboardingPayload;
  createPost: CreatePostPayload;
  createPostPollVote: CreatePostPollVotePayload;
  createPostPrompt: CreatePostPromptPayload;
  createPostTagOption: CreatePostTagOptionPayload;
  createPresignedDownloadUrl: CreatePresignedDownloadUrlPayload;
  createPresignedUploadUrl: CreatePresignedUploadUrlPayload;
  createRoomMember: CreateRoomMemberPayload;
  createRoomOption: CreateRoomOptionPayload;
  createShelf: CreateShelfPayload;
  createShelfProduct: CreateShelfProductPayload;
  createShelfWithProduct: CreateShelfWithProductPayload;
  createTrialBrand: CreateTrialBrandPayload;
  createUser: CreateUserPayload;
  createYotpoDeveloperInstallationLink: CreateYotpoDeveloperInstallationLinkPayload;
  deleteBannedWord: DeleteBannedWordPayload;
  deleteBrand: DeleteBrandPayload;
  deleteBrandApiKey: DeleteBrandApiKeyPayload;
  deleteBrandEvent: DeleteBrandEventPayload;
  deleteBrandKlaviyoIntegration: DeleteBrandKlaviyoIntegrationPayload;
  deleteBrandLink: DeleteBrandLinkPayload;
  deleteBrandMember: DeleteBrandMemberPayload;
  deleteBrandMemberBadge: DeleteBrandMemberBadgePayload;
  deleteBrandMemberLabel: DeleteBrandMemberLabelPayload;
  deleteBrandMemberLabelOption: DeleteBrandMemberLabelOptionPayload;
  deleteBrandMemberNotificationSetting: DeleteBrandMemberNotificationSettingPayload;
  deleteBrandWebhook: DeleteBrandWebhookPayload;
  deleteChallenge: DeleteChallengePayload;
  deleteChallengeInput: DeleteChallengeInputPayload;
  deleteChallengeParticipant: DeleteChallengeParticipantPayload;
  deleteContentReport: DeleteContentReportPayload;
  deleteCustomTraitGroup: DeleteCustomTraitGroupPayload;
  deleteEventAttendee: DeleteEventAttendeePayload;
  deleteFaq: DeleteFaqPayload;
  deleteLoyaltyAction: DeleteLoyaltyActionPayload;
  deleteLoyaltyDiscount: DeleteLoyaltyDiscountPayload;
  deleteMemberGroup: DeleteMemberGroupPayload;
  deleteOnboarding: DeleteOnboardingPayload;
  deletePost: DeletePostPayload;
  deletePostPrompt: DeletePostPromptPayload;
  deletePostTagOption: DeletePostTagOptionPayload;
  deleteRoomMember: DeleteRoomMemberPayload;
  deleteRoomOption: DeleteRoomOptionPayload;
  deleteShelf: DeleteShelfPayload;
  deleteShelfProduct: DeleteShelfProductPayload;
  deleteUser: DeleteUserPayload;
  disconnectBrandInveterateIntegration: DisconnectBrandInveterateIntegrationPayload;
  disconnectBrandShopifyApiKey: DisconnectBrandShopifyApiKeyPayload;
  /** Disconnect a brand from Yotpo loyalty program */
  disconnectBrandYotpoIntegration: DisconnectBrandYotpoIntegrationPayload;
  generateBrandShopifyApiKey: GenerateBrandShopifyApiKeyPayload;
  getMuxUploadUrl: MuxUploadResponse;
  handleYotpoDeveloperAuthCallback: HandleYotpoDeveloperAuthCallbackPayload;
  highlightPost: HighlightPostPayload;
  joinBrand: JoinBrandPayload;
  markAllUserNotificationsAsRead: MarkAllUserNotificationsAsReadPayload;
  pinPost: PinPostPayload;
  redeemDiscountCode: RedeemDiscountCodePayload;
  redeemYotpoPoints: RedeemYotpoPointsPayload;
  registerLastOpenedNotificationsAt: RegisterLastOpenedNotificationsAtPayload;
  registerShopifyShop: RegisterShopifyShopPayload;
  registerUser: RegisterUserPayload;
  reorderRoomOptions: ReorderRoomOptionsPayload;
  reportContent: ReportContentPayload;
  requestPhoneVerification: RequestPhoneVerificationPayload;
  sendEmailVerification: SendEmailVerificationPayload;
  setPostCommentLiked: SetPostCommentLikedPayload;
  setPostLiked: SetPostLikedPayload;
  setShopifyProductSyncEnabled: SetShopifyProductSyncEnabledPayload;
  softDeletePostComment: SoftDeletePostCommentPayload;
  uninstallYotpoDeveloperIntegration: UninstallYotpoDeveloperIntegrationPayload;
  unregisterShopifyShop: UnregisterShopifyShopPayload;
  unsubscribeUserUsingToken: UnsubscribeUserUsingTokenPayload;
  updateBannedWord: UpdateBannedWordPayload;
  updateBrand: UpdateBrandPayload;
  updateBrandApiKey: UpdateBrandApiKeyPayload;
  updateBrandEvent: UpdateBrandEventPayload;
  updateBrandInveterateIntegration: UpdateBrandInveterateIntegrationPayload;
  updateBrandKlaviyoIntegration: UpdateBrandKlaviyoIntegrationPayload;
  updateBrandLink: UpdateBrandLinkPayload;
  updateBrandMember: UpdateBrandMemberPayload;
  updateBrandMemberBadge: UpdateBrandMemberBadgePayload;
  updateBrandMemberLabel: UpdateBrandMemberLabelPayload;
  updateBrandMemberLabelOption: UpdateBrandMemberLabelOptionPayload;
  updateBrandMemberNotificationSetting: UpdateBrandMemberNotificationSettingPayload;
  updateBrandWebhook: UpdateBrandWebhookPayload;
  updateBrandYotpoDeveloperIntegration: UpdateBrandYotpoDeveloperIntegrationPayload;
  updateBrandYotpoRedemptionUrl: UpdateBrandYotpoRedemptionUrlPayload;
  updateChallenge: UpdateChallengePayload;
  updateChallengeInput: UpdateChallengeInputPayload;
  updateChallengeParticipant: UpdateChallengeParticipantPayload;
  updateComment: UpdateCommentPayload;
  updateCustomTraitGroup: UpdateCustomTraitGroupPayload;
  updateCustomTraits: UpdateCustomTraitsPayload;
  updateEventAttendee: UpdateEventAttendeePayload;
  updateFaq: UpdateFaqPayload;
  updateLoyaltyAction: UpdateLoyaltyActionPayload;
  updateMemberGroup: UpdateMemberGroupPayload;
  updateOnboarding: UpdateOnboardingPayload;
  updatePost: UpdatePostPayload;
  updatePostPrompt: UpdatePostPromptPayload;
  updatePostTagOption: UpdatePostTagOptionPayload;
  updateRoomMember: UpdateRoomMemberPayload;
  updateRoomOption: UpdateRoomOptionPayload;
  updateShelf: UpdateShelfPayload;
  updateShelfProduct: UpdateShelfProductPayload;
  updateUser: UpdateUserPayload;
  updateUserLoyaltyPointsFromYotpo: UpdateUserLoyaltyPointsFromYotpoPayload;
  updateUserNotification: UpdateUserNotificationPayload;
  visitBrand: VisitBrandPayload;
};


export type MutationApprovePostArgs = {
  input: ApprovePostInput;
};


export type MutationBanBrandMemberArgs = {
  input: BanBrandMemberInput;
};


export type MutationCheckEmailVerificationArgs = {
  input: CheckEmailVerificationInput;
};


export type MutationCheckPhoneVerificationArgs = {
  input: CheckPhoneVerificationInput;
};


export type MutationCommentOnPostArgs = {
  input: CommentOnPostInput;
};


export type MutationConnectBrandInveterateIntegrationArgs = {
  input: ConnectBrandInveterateIntegrationInput;
};


export type MutationConnectBrandYotpoIntegrationArgs = {
  input: ConnectBrandYotpoIntegrationInput;
};


export type MutationCreateBannedWordArgs = {
  input: CreateBannedWordInput;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateBrandApiKeyArgs = {
  input: CreateBrandApiKeyInput;
};


export type MutationCreateBrandEventArgs = {
  input: CreateBrandEventInput;
};


export type MutationCreateBrandKlaviyoIntegrationArgs = {
  input: CreateBrandKlaviyoIntegrationInput;
};


export type MutationCreateBrandLinkArgs = {
  input: CreateBrandLinkInput;
};


export type MutationCreateBrandMemberArgs = {
  input: CreateBrandMemberInput;
};


export type MutationCreateBrandMemberBadgeArgs = {
  input: CreateBrandMemberBadgeInput;
};


export type MutationCreateBrandMemberLabelArgs = {
  input: CreateBrandMemberLabelInput;
};


export type MutationCreateBrandMemberLabelOptionArgs = {
  input: CreateBrandMemberLabelOptionInput;
};


export type MutationCreateBrandMemberNotificationSettingArgs = {
  input: CreateBrandMemberNotificationSettingInput;
};


export type MutationCreateBrandWebhookArgs = {
  input: CreateBrandWebhookInput;
};


export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};


export type MutationCreateChallengeInputArgs = {
  input: CreateChallengeInputInput;
};


export type MutationCreateChallengeParticipantArgs = {
  input: CreateChallengeParticipantInput;
};


export type MutationCreateCustomTraitGroupArgs = {
  input: CreateCustomTraitGroupInput;
};


export type MutationCreateEventAttendeeArgs = {
  input: CreateEventAttendeeInput;
};


export type MutationCreateFaqArgs = {
  input: CreateFaqInput;
};


export type MutationCreateLoyaltyActionArgs = {
  input: CreateLoyaltyActionInput;
};


export type MutationCreateLoyaltyDiscountArgs = {
  input: CreateLoyaltyDiscountInput;
};


export type MutationCreateMemberGroupArgs = {
  input: CreateMemberGroupInput;
};


export type MutationCreateOnboardingArgs = {
  input: CreateOnboardingInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreatePostPollVoteArgs = {
  input: CreatePostPollVoteInput;
};


export type MutationCreatePostPromptArgs = {
  input: CreatePostPromptInput;
};


export type MutationCreatePostTagOptionArgs = {
  input: CreatePostTagOptionInput;
};


export type MutationCreatePresignedDownloadUrlArgs = {
  input: CreatePresignedDownloadUrlInput;
};


export type MutationCreatePresignedUploadUrlArgs = {
  input: CreatePresignedUploadUrlInput;
};


export type MutationCreateRoomMemberArgs = {
  input: CreateRoomMemberInput;
};


export type MutationCreateRoomOptionArgs = {
  input: CreateRoomOptionInput;
};


export type MutationCreateShelfArgs = {
  input: CreateShelfInput;
};


export type MutationCreateShelfProductArgs = {
  input: CreateShelfProductInput;
};


export type MutationCreateShelfWithProductArgs = {
  input: CreateShelfWithProductInput;
};


export type MutationCreateTrialBrandArgs = {
  input: CreateTrialBrandInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateYotpoDeveloperInstallationLinkArgs = {
  input: CreateYotpoDeveloperInstallationLinkInput;
};


export type MutationDeleteBannedWordArgs = {
  input: DeleteBannedWordInput;
};


export type MutationDeleteBrandArgs = {
  input: DeleteBrandInput;
};


export type MutationDeleteBrandApiKeyArgs = {
  input: DeleteBrandApiKeyInput;
};


export type MutationDeleteBrandEventArgs = {
  input: DeleteBrandEventInput;
};


export type MutationDeleteBrandKlaviyoIntegrationArgs = {
  input: DeleteBrandKlaviyoIntegrationInput;
};


export type MutationDeleteBrandLinkArgs = {
  input: DeleteBrandLinkInput;
};


export type MutationDeleteBrandMemberArgs = {
  input: DeleteBrandMemberInput;
};


export type MutationDeleteBrandMemberBadgeArgs = {
  input: DeleteBrandMemberBadgeInput;
};


export type MutationDeleteBrandMemberLabelArgs = {
  input: DeleteBrandMemberLabelInput;
};


export type MutationDeleteBrandMemberLabelOptionArgs = {
  input: DeleteBrandMemberLabelOptionInput;
};


export type MutationDeleteBrandMemberNotificationSettingArgs = {
  input: DeleteBrandMemberNotificationSettingInput;
};


export type MutationDeleteBrandWebhookArgs = {
  input: DeleteBrandWebhookInput;
};


export type MutationDeleteChallengeArgs = {
  input: DeleteChallengeInput;
};


export type MutationDeleteChallengeInputArgs = {
  input: DeleteChallengeInputInput;
};


export type MutationDeleteChallengeParticipantArgs = {
  input: DeleteChallengeParticipantInput;
};


export type MutationDeleteContentReportArgs = {
  input: DeleteContentReportInput;
};


export type MutationDeleteCustomTraitGroupArgs = {
  input: DeleteCustomTraitGroupInput;
};


export type MutationDeleteEventAttendeeArgs = {
  input: DeleteEventAttendeeInput;
};


export type MutationDeleteFaqArgs = {
  input: DeleteFaqInput;
};


export type MutationDeleteLoyaltyActionArgs = {
  input: DeleteLoyaltyActionInput;
};


export type MutationDeleteLoyaltyDiscountArgs = {
  input: DeleteLoyaltyDiscountInput;
};


export type MutationDeleteMemberGroupArgs = {
  input: DeleteMemberGroupInput;
};


export type MutationDeleteOnboardingArgs = {
  input: DeleteOnboardingInput;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationDeletePostPromptArgs = {
  input: DeletePostPromptInput;
};


export type MutationDeletePostTagOptionArgs = {
  input: DeletePostTagOptionInput;
};


export type MutationDeleteRoomMemberArgs = {
  input: DeleteRoomMemberInput;
};


export type MutationDeleteRoomOptionArgs = {
  input: DeleteRoomOptionInput;
};


export type MutationDeleteShelfArgs = {
  input: DeleteShelfInput;
};


export type MutationDeleteShelfProductArgs = {
  input: DeleteShelfProductInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDisconnectBrandInveterateIntegrationArgs = {
  input: DisconnectBrandInveterateIntegrationInput;
};


export type MutationDisconnectBrandShopifyApiKeyArgs = {
  input: DisconnectBrandShopifyApiKeyInput;
};


export type MutationDisconnectBrandYotpoIntegrationArgs = {
  input: DisconnectBrandYotpoIntegrationInput;
};


export type MutationGenerateBrandShopifyApiKeyArgs = {
  input: GenerateBrandShopifyApiKeyInput;
};


export type MutationGetMuxUploadUrlArgs = {
  input: GetMuxUploadUrlInput;
};


export type MutationHandleYotpoDeveloperAuthCallbackArgs = {
  input: HandleYotpoDeveloperAuthCallbackInput;
};


export type MutationHighlightPostArgs = {
  input: HighlightPostInput;
};


export type MutationJoinBrandArgs = {
  input: JoinBrandInput;
};


export type MutationMarkAllUserNotificationsAsReadArgs = {
  input: MarkAllUserNotificationsAsReadInput;
};


export type MutationPinPostArgs = {
  input: PinPostInput;
};


export type MutationRedeemDiscountCodeArgs = {
  input: RedeemDiscountCodeInput;
};


export type MutationRedeemYotpoPointsArgs = {
  input: RedeemYotpoPointsInput;
};


export type MutationRegisterLastOpenedNotificationsAtArgs = {
  input: RegisterLastOpenedNotificationsAtInput;
};


export type MutationRegisterShopifyShopArgs = {
  input: RegisterShopifyShopInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationReorderRoomOptionsArgs = {
  input: ReorderRoomOptionsInput;
};


export type MutationReportContentArgs = {
  input: ReportContentInput;
};


export type MutationRequestPhoneVerificationArgs = {
  input: RequestPhoneVerificationInput;
};


export type MutationSendEmailVerificationArgs = {
  input: SendEmailVerificationInput;
};


export type MutationSetPostCommentLikedArgs = {
  input: SetPostCommentLikedInput;
};


export type MutationSetPostLikedArgs = {
  input: SetPostLikedInput;
};


export type MutationSetShopifyProductSyncEnabledArgs = {
  input: SetShopifyProductSyncEnabledInput;
};


export type MutationSoftDeletePostCommentArgs = {
  input: SoftDeletePostCommentInput;
};


export type MutationUninstallYotpoDeveloperIntegrationArgs = {
  input: UninstallYotpoDeveloperIntegrationInput;
};


export type MutationUnregisterShopifyShopArgs = {
  input: UnregisterShopifyShopInput;
};


export type MutationUnsubscribeUserUsingTokenArgs = {
  input: UnsubscribeUserUsingTokenInput;
};


export type MutationUpdateBannedWordArgs = {
  input: UpdateBannedWordInput;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationUpdateBrandApiKeyArgs = {
  input: UpdateBrandApiKeyInput;
};


export type MutationUpdateBrandEventArgs = {
  input: UpdateBrandEventInput;
};


export type MutationUpdateBrandInveterateIntegrationArgs = {
  input: UpdateBrandInveterateIntegrationInput;
};


export type MutationUpdateBrandKlaviyoIntegrationArgs = {
  input: UpdateBrandKlaviyoIntegrationInput;
};


export type MutationUpdateBrandLinkArgs = {
  input: UpdateBrandLinkInput;
};


export type MutationUpdateBrandMemberArgs = {
  input: UpdateBrandMemberInput;
};


export type MutationUpdateBrandMemberBadgeArgs = {
  input: UpdateBrandMemberBadgeInput;
};


export type MutationUpdateBrandMemberLabelArgs = {
  input: UpdateBrandMemberLabelInput;
};


export type MutationUpdateBrandMemberLabelOptionArgs = {
  input: UpdateBrandMemberLabelOptionInput;
};


export type MutationUpdateBrandMemberNotificationSettingArgs = {
  input: UpdateBrandMemberNotificationSettingInput;
};


export type MutationUpdateBrandWebhookArgs = {
  input: UpdateBrandWebhookInput;
};


export type MutationUpdateBrandYotpoDeveloperIntegrationArgs = {
  input: UpdateBrandYotpoDeveloperIntegrationInput;
};


export type MutationUpdateBrandYotpoRedemptionUrlArgs = {
  input: UpdateBrandYotpoRedemptionUrlInput;
};


export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};


export type MutationUpdateChallengeInputArgs = {
  input: UpdateChallengeInputInput;
};


export type MutationUpdateChallengeParticipantArgs = {
  input: UpdateChallengeParticipantInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateCustomTraitGroupArgs = {
  input: UpdateCustomTraitGroupInput;
};


export type MutationUpdateCustomTraitsArgs = {
  input: UpdateCustomTraitsInput;
};


export type MutationUpdateEventAttendeeArgs = {
  input: UpdateEventAttendeeInput;
};


export type MutationUpdateFaqArgs = {
  input: UpdateFaqInput;
};


export type MutationUpdateLoyaltyActionArgs = {
  input: UpdateLoyaltyActionInput;
};


export type MutationUpdateMemberGroupArgs = {
  input: UpdateMemberGroupInput;
};


export type MutationUpdateOnboardingArgs = {
  input: UpdateOnboardingInput;
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationUpdatePostPromptArgs = {
  input: UpdatePostPromptInput;
};


export type MutationUpdatePostTagOptionArgs = {
  input: UpdatePostTagOptionInput;
};


export type MutationUpdateRoomMemberArgs = {
  input: UpdateRoomMemberInput;
};


export type MutationUpdateRoomOptionArgs = {
  input: UpdateRoomOptionInput;
};


export type MutationUpdateShelfArgs = {
  input: UpdateShelfInput;
};


export type MutationUpdateShelfProductArgs = {
  input: UpdateShelfProductInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserLoyaltyPointsFromYotpoArgs = {
  input: UpdateUserLoyaltyPointsFromYotpoInput;
};


export type MutationUpdateUserNotificationArgs = {
  input: UpdateUserNotificationInput;
};


export type MutationVisitBrandArgs = {
  input: VisitBrandInput;
};

export type MuxUploadResponse = {
  __typename?: 'MuxUploadResponse';
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Onboarding = {
  __typename?: 'Onboarding';
  acceptedGuidelines?: Maybe<Scalars['Boolean']['output']>;
  brandId: Scalars['Uuid']['output'];
  completedChallenges?: Maybe<Scalars['Boolean']['output']>;
  completedCustomTraits: Scalars['Boolean']['output'];
  completedHairDetails?: Maybe<Scalars['Boolean']['output']>;
  completedLoyalty?: Maybe<Scalars['Boolean']['output']>;
  completedPWA?: Maybe<Scalars['Boolean']['output']>;
  completedShelves?: Maybe<Scalars['Boolean']['output']>;
  completedShoeDetails?: Maybe<Scalars['Boolean']['output']>;
  completedSkinDetails?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['Uuid']['output'];
};

export type OnboardingCreateData = {
  acceptedGuidelines?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['Uuid']['input'];
  completedChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  completedCustomTraits?: InputMaybe<Scalars['Boolean']['input']>;
  completedHairDetails?: InputMaybe<Scalars['Boolean']['input']>;
  completedLoyalty?: InputMaybe<Scalars['Boolean']['input']>;
  completedPWA?: InputMaybe<Scalars['Boolean']['input']>;
  completedShoeDetails?: InputMaybe<Scalars['Boolean']['input']>;
  completedSkinDetails?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['Uuid']['input'];
};

export type OnboardingPrimaryKey = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type OnboardingUpdateData = {
  acceptedGuidelines?: InputMaybe<Scalars['Boolean']['input']>;
  completedChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  completedCustomTraits?: InputMaybe<Scalars['Boolean']['input']>;
  completedHairDetails?: InputMaybe<Scalars['Boolean']['input']>;
  completedLoyalty?: InputMaybe<Scalars['Boolean']['input']>;
  completedPWA?: InputMaybe<Scalars['Boolean']['input']>;
  completedShelves?: InputMaybe<Scalars['Boolean']['input']>;
  completedShoeDetails?: InputMaybe<Scalars['Boolean']['input']>;
  completedSkinDetails?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PinPostData = {
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input type for pinPost mutation */
export type PinPostInput = {
  data: PinPostData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for pinPost mutation */
export type PinPostPayload = {
  __typename?: 'PinPostPayload';
  post: Post;
};

export type Post = {
  __typename?: 'Post';
  attachments: Array<PostAttachment>;
  brand: Brand;
  brandId: Scalars['Uuid']['output'];
  comments: Array<PostComment>;
  commentsCount: Scalars['Int']['output'];
  contentHtml: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  highlightedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isApprovalRequired: Scalars['Boolean']['output'];
  isLikedByMe: Scalars['Boolean']['output'];
  isPinned: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  lastReplyReminderAt?: Maybe<Scalars['DateTime']['output']>;
  likes: Array<PostLike>;
  likesCount: Scalars['Int']['output'];
  poll?: Maybe<PostPoll>;
  productMentions: Array<PostProductMention>;
  productReview?: Maybe<PostProductReview>;
  publishedAt: Scalars['DateTime']['output'];
  room: RoomOption;
  roomId: Scalars['Uuid']['output'];
  shouldNotifyMembers: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  tags: Array<PostTag>;
  title: Scalars['String']['output'];
  type: PostType;
  url: Scalars['String']['output'];
  user: User;
  userId: Scalars['Uuid']['output'];
};

export type PostAttachment = {
  __typename?: 'PostAttachment';
  cachedUrlPreview?: Maybe<CachedUrlPreview>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  postId: Scalars['Uuid']['output'];
  type: PostAttachmentType;
  url?: Maybe<Scalars['String']['output']>;
};

export type PostAttachmentType =
  | 'IMAGE'
  | 'URL'
  | 'VIDEO';

export type PostComment = {
  __typename?: 'PostComment';
  attachments: Array<PostCommentAttachment>;
  cachedUrlPreview?: Maybe<CachedUrlPreview>;
  contentHtml: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  embeddedUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isEdited: Scalars['Boolean']['output'];
  isLikedByMe: Scalars['Boolean']['output'];
  likes: Array<PostCommentLike>;
  likesCount: Scalars['Int']['output'];
  post: Post;
  postId: Scalars['Uuid']['output'];
  productMentions: Array<PostCommentProductMention>;
  replyComments: Array<PostComment>;
  replyToComment?: Maybe<PostComment>;
  replyToCommentId?: Maybe<Scalars['Uuid']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Uuid']['output']>;
};

export type PostCommentAttachment = {
  __typename?: 'PostCommentAttachment';
  caption?: Maybe<Scalars['String']['output']>;
  commentId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['Uuid']['output']>;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  type: PostAttachmentType;
  url?: Maybe<Scalars['String']['output']>;
};

export type PostCommentEmbeddedAttachmentsData = {
  caption?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<FileUploadInput>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  position: Scalars['Int']['input'];
  type: PostAttachmentType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PostCommentLike = {
  __typename?: 'PostCommentLike';
  comment: PostComment;
  commentId: Scalars['Uuid']['output'];
  user: User;
  userId: Scalars['Uuid']['output'];
};

export type PostCommentMention = {
  __typename?: 'PostCommentMention';
  comment: PostComment;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type PostCommentProductMention = {
  __typename?: 'PostCommentProductMention';
  brandProduct: BrandProduct;
  brandProductId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  postComment: PostComment;
  postCommentId: Scalars['Uuid']['output'];
};

export type PostConnection = {
  __typename?: 'PostConnection';
  edges: Array<PostEdge>;
  pageInfo: PageInfo;
};

export type PostCreateData = {
  attachments?: InputMaybe<Array<PostEmbeddedAttachmentsData>>;
  brandId: Scalars['Uuid']['input'];
  contentHtml: Scalars['String']['input'];
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  poll?: InputMaybe<PostEmbeddedPollData>;
  postPromptId?: InputMaybe<Scalars['Uuid']['input']>;
  productReview?: InputMaybe<PostEmbeddedProductReviewData>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  roomId: Scalars['Uuid']['input'];
  shouldNotifyMembers?: InputMaybe<Scalars['Boolean']['input']>;
  tagIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  title: Scalars['String']['input'];
  type: PostType;
  /** @deprecated No longer needed */
  tzOffset?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Uuid']['input'];
};

export type PostEdge = {
  __typename?: 'PostEdge';
  cursor: Scalars['String']['output'];
  node: Post;
};

export type PostEmbeddedAttachmentsData = {
  caption?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<FileUploadInput>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  position: Scalars['Int']['input'];
  type: PostAttachmentType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PostEmbeddedPollData = {
  choices?: InputMaybe<Array<PostPollEmbeddedChoicesData>>;
  votingEndsAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PostEmbeddedProductReviewData = {
  brandProductId: Scalars['Uuid']['input'];
  rating: Scalars['Int']['input'];
};

export type PostLike = {
  __typename?: 'PostLike';
  createdAt: Scalars['DateTime']['output'];
  post: Post;
  postId: Scalars['Uuid']['output'];
  userId: Scalars['Uuid']['output'];
};

export type PostMention = {
  __typename?: 'PostMention';
  id: Scalars['ID']['output'];
  post: Post;
  user: User;
};

export type PostPoll = {
  __typename?: 'PostPoll';
  choices: Array<PostPollChoice>;
  id: Scalars['ID']['output'];
  post: Post;
  votingEndsAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PostPollChoice = {
  __typename?: 'PostPollChoice';
  hasUserVoted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageFile?: Maybe<File>;
  pollId: Scalars['Uuid']['output'];
  position: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  voteCount: Scalars['Int']['output'];
};

export type PostPollEmbeddedChoicesData = {
  id?: InputMaybe<Scalars['Uuid']['input']>;
  imageFile?: InputMaybe<FileUploadInput>;
  position: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type PostProductMention = {
  __typename?: 'PostProductMention';
  brandProduct: BrandProduct;
  brandProductId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  post: Post;
  postId: Scalars['Uuid']['output'];
};

export type PostProductReview = {
  __typename?: 'PostProductReview';
  brandProduct: BrandProduct;
  brandProductId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  rating: Scalars['Int']['output'];
};

export type PostPrompt = {
  __typename?: 'PostPrompt';
  body?: Maybe<Scalars['String']['output']>;
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  postCount: Scalars['Int']['output'];
  prompt: Scalars['String']['output'];
  promptUsed?: Maybe<Scalars['Boolean']['output']>;
  roomId: Scalars['Uuid']['output'];
  title: Scalars['String']['output'];
};


export type PostPromptPromptUsedArgs = {
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type PostPromptCreateData = {
  body?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['Uuid']['input'];
  prompt: Scalars['String']['input'];
  roomId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};

export type PostPromptUpdateData = {
  body?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PostSearchResult = {
  __typename?: 'PostSearchResult';
  highlightHtml: Scalars['String']['output'];
  post?: Maybe<Post>;
  postId: Scalars['Uuid']['output'];
};

export type PostSearchResultsPayload = {
  __typename?: 'PostSearchResultsPayload';
  results: Array<PostSearchResult>;
  totalCount: Scalars['Int']['output'];
};

export type PostTag = {
  __typename?: 'PostTag';
  brandId: Scalars['Uuid']['output'];
  post: Post;
  postId: Scalars['Uuid']['output'];
  postTagOption: PostTagOption;
  postTagOptionId: Scalars['Uuid']['output'];
};

export type PostTagOption = {
  __typename?: 'PostTagOption';
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  tagCount: Scalars['Int']['output'];
  tags: Array<PostTag>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PostTagOptionCreateData = {
  brandId: Scalars['Uuid']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Uuid']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PostTagOptionUpdateData = {
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PostType =
  | 'POLL'
  | 'PRODUCT_REVIEW'
  | 'TEXT'
  | 'WELCOME';

export type PostUpdateData = {
  attachments?: InputMaybe<Array<PostEmbeddedAttachmentsData>>;
  contentHtml?: InputMaybe<Scalars['String']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  lastReplyReminderAt?: InputMaybe<Scalars['DateTime']['input']>;
  poll?: InputMaybe<PostEmbeddedPollData>;
  productReview?: InputMaybe<PostEmbeddedProductReviewData>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  tagIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PostsFilter = {
  brandId: Scalars['Uuid']['input'];
  filterBy?: InputMaybe<PostsFilterBy>;
  isApprovalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']['input']>;
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  isProcessing?: InputMaybe<Scalars['Boolean']['input']>;
  isScheduledPost?: InputMaybe<Scalars['Boolean']['input']>;
  postTagOptionIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  roomId?: InputMaybe<Scalars['Uuid']['input']>;
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type PostsFilterBy =
  | 'ALL'
  | 'MINE'
  | 'NO_ADMIN_RESPONSE'
  | 'NO_RESPONSE';

export type PostsOrderBy =
  | 'DEFAULT'
  | 'IS_PINNED_PUBLISHED_AT'
  | 'MOST_ENGAGED'
  | 'PUBLISHED_AT';

export type PresignedUrlField = {
  __typename?: 'PresignedUrlField';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ProfileProperty =
  | 'BIRTHDAY'
  | 'EMAIL'
  | 'EYE_COLOR'
  | 'FIRST_NAME'
  | 'HAIR_COLOR'
  | 'HAIR_CONCERN'
  | 'HAIR_LENGTH'
  | 'HAIR_TEXTURE'
  | 'JOINED_DATE'
  | 'LAST_NAME'
  | 'LOCATION'
  | 'SKIN_TONE'
  | 'SKIN_TYPE';

export type Query = {
  __typename?: 'Query';
  bannedWord: BannedWord;
  bannedWords: Array<BannedWord>;
  brand: Brand;
  brandApiKey: BrandApiKey;
  brandApiKeys: Array<BrandApiKey>;
  brandByDomain?: Maybe<Brand>;
  brandByDomainForSitemap?: Maybe<Brand>;
  brandEvent: BrandEvent;
  brandEventBySlug: BrandEvent;
  brandEvents: Array<BrandEvent>;
  brandFontOption: BrandFontOption;
  brandFontOptions: Array<BrandFontOption>;
  brandLink: BrandLink;
  brandLinks: Array<BrandLink>;
  brandLogEntries: Array<BrandLogEntry>;
  brandLogEntriesConnection: BrandLogEntryConnection;
  brandLogEntry: BrandLogEntry;
  brandMemberBadge: BrandMemberBadge;
  brandMemberBadges: Array<BrandMemberBadge>;
  brandMemberLabelOption: BrandMemberLabelOption;
  brandMemberLabelOptions: Array<BrandMemberLabelOption>;
  brandMemberNotificationSetting?: Maybe<BrandMemberNotificationSetting>;
  brandMemberNotificationSettings: Array<BrandMemberNotificationSetting>;
  brandMembersPaginated: Array<BrandMember>;
  brandProductByUrl?: Maybe<BrandProduct>;
  brandProductsConnection: QueryBrandProductsConnection;
  brandWebhook: BrandWebhook;
  brandWebhooks: Array<BrandWebhook>;
  brandYotpoLists: Array<YotpoList>;
  brands: Array<Brand>;
  cachedUrlPreview: CachedUrlPreview;
  cachedUrlPreviews: Array<CachedUrlPreview>;
  challenge: Challenge;
  challengeBySlug: Challenge;
  challenges: Array<Challenge>;
  contentReport: ContentReport;
  contentReports: Array<ContentReport>;
  customTraitGroup: CustomTraitGroup;
  customTraitGroups: Array<CustomTraitGroup>;
  customTraitGroupsForUser: Array<CustomTraitGroup>;
  feedItems: FeedItemConnection;
  isUserAllowedToJoin: Scalars['Boolean']['output'];
  loyaltyAction: LoyaltyAction;
  loyaltyActionsByBrand: Array<LoyaltyAction>;
  loyaltyDiscount: LoyaltyDiscount;
  loyaltyDiscountsByBrand: Array<LoyaltyDiscount>;
  loyaltyLogsByBrand: Array<LoyaltyLog>;
  memberGroup: MemberGroup;
  memberGroups: Array<MemberGroup>;
  membersByBrand: BrandMemberConnection;
  post: Post;
  postByIdOrSlug?: Maybe<Post>;
  postComment: PostComment;
  postCommentsByUser: Array<PostComment>;
  postPrompt: PostPrompt;
  postPromptsByBrand: Array<PostPrompt>;
  postPromptsByRoom: Array<PostPrompt>;
  postTagOption: PostTagOption;
  postTagOptionBySlug?: Maybe<PostTagOption>;
  postTagOptions: Array<PostTagOption>;
  postTagOptionsWithPosts: Array<PostTagOption>;
  posts: Array<Post>;
  postsByBrand: PostConnection;
  roomOption: RoomOption;
  roomOptions: Array<RoomOption>;
  searchLocations: Array<SearchLocationResult>;
  searchPosts: PostSearchResultsPayload;
  shelf: Shelf;
  shelfForBrandMember: Array<Shelf>;
  shelfProduct: ShelfProduct;
  shelfProducts: Array<ShelfProduct>;
  shelves: Array<Shelf>;
  traitColor: TraitColor;
  traitColors: Array<TraitColor>;
  urlPreviewByUrl?: Maybe<CachedUrlPreview>;
  user: User;
  userByUsername: User;
  userNotification: UserNotification;
  userNotifications: Array<UserNotification>;
  userNotificationsByBrand: UserNotificationConnection;
  userShoeSize: UserShoeSize;
  userShoeSizes: Array<UserShoeSize>;
  userShoeType: UserShoeType;
  userShoeTypes: Array<UserShoeType>;
  usernameExists: Scalars['Boolean']['output'];
  users: Array<User>;
  usersByBrand: Array<User>;
  usersByBrandConnection: QueryUsersByBrandConnection;
  yotpoLoyaltyLogsByBrand: Array<YotpoLoyaltyLog>;
};


export type QueryBannedWordArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBannedWordsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryBrandArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandApiKeyArgs = {
  brandId: Scalars['Uuid']['input'];
  id: Scalars['Uuid']['input'];
};


export type QueryBrandApiKeysArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryBrandByDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryBrandByDomainForSitemapArgs = {
  domain: Scalars['String']['input'];
};


export type QueryBrandEventArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandEventBySlugArgs = {
  brandId: Scalars['Uuid']['input'];
  slug: Scalars['String']['input'];
};


export type QueryBrandEventsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryBrandFontOptionArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandLinkArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandLogEntriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where: BrandLogEntryWhereInput;
};


export type QueryBrandLogEntryArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandMemberBadgeArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandMemberBadgesArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryBrandMemberLabelOptionArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandMemberLabelOptionsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryBrandMemberNotificationSettingArgs = {
  id: BrandMemberNotificationSettingPrimaryKey;
};


export type QueryBrandMembersPaginatedArgs = {
  brandId: Scalars['Uuid']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BrandMembersSortByOption>;
  sortDescending?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBrandProductByUrlArgs = {
  brandId: Scalars['Uuid']['input'];
  url: Scalars['String']['input'];
};


export type QueryBrandProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['Uuid']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBrandWebhookArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryBrandWebhooksArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryBrandYotpoListsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryCachedUrlPreviewArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryChallengeArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryChallengeBySlugArgs = {
  brandId: Scalars['Uuid']['input'];
  slug: Scalars['String']['input'];
};


export type QueryChallengesArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryContentReportArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryContentReportsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryCustomTraitGroupArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryCustomTraitGroupsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryCustomTraitGroupsForUserArgs = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};


export type QueryFeedItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['Uuid']['input'];
  filterBy?: InputMaybe<FeedFilterBy>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  shelfUpdateAfter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryIsUserAllowedToJoinArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryLoyaltyActionArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryLoyaltyActionsByBrandArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryLoyaltyDiscountArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryLoyaltyDiscountsByBrandArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryLoyaltyLogsByBrandArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryMemberGroupArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryMemberGroupsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryMembersByBrandArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['Uuid']['input'];
  filter?: InputMaybe<MembersFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: MembersOrderBy;
};


export type QueryPostArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryPostByIdOrSlugArgs = {
  brandId: Scalars['Uuid']['input'];
  idOrSlug: Scalars['String']['input'];
};


export type QueryPostCommentArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryPostCommentsByUserArgs = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};


export type QueryPostPromptArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryPostPromptsByBrandArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QueryPostPromptsByRoomArgs = {
  roomId: Scalars['Uuid']['input'];
};


export type QueryPostTagOptionArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryPostTagOptionBySlugArgs = {
  brandId: Scalars['Uuid']['input'];
  slug: Scalars['String']['input'];
};


export type QueryPostTagOptionsArgs = {
  brandId: Scalars['Uuid']['input'];
  roomId?: InputMaybe<Scalars['Uuid']['input']>;
};


export type QueryPostTagOptionsWithPostsArgs = {
  brandId: Scalars['Uuid']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPostsArgs = {
  filter: PostsFilter;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: PostsOrderBy;
};


export type QueryPostsByBrandArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: PostsFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PostsOrderBy>;
};


export type QueryRoomOptionArgs = {
  brandId: Scalars['Uuid']['input'];
  slug: Scalars['String']['input'];
};


export type QueryRoomOptionsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type QuerySearchLocationsArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchPostsArgs = {
  brandId: Scalars['Uuid']['input'];
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


export type QueryShelfArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryShelfForBrandMemberArgs = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};


export type QueryShelfProductArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryTraitColorArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryUrlPreviewByUrlArgs = {
  url: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryUserByUsernameArgs = {
  brandId?: InputMaybe<Scalars['Uuid']['input']>;
  username: Scalars['String']['input'];
};


export type QueryUserNotificationArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryUserNotificationsByBrandArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brandId: Scalars['Uuid']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Uuid']['input'];
};


export type QueryUserShoeSizeArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryUserShoeTypeArgs = {
  id: Scalars['Uuid']['input'];
};


export type QueryUsernameExistsArgs = {
  brandId: Scalars['Uuid']['input'];
  username: Scalars['String']['input'];
};


export type QueryUsersByBrandArgs = {
  filters: UserFilterByBrandInput;
};


export type QueryUsersByBrandConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters: UserFilterByBrandInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryYotpoLoyaltyLogsByBrandArgs = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type QueryBrandProductsConnection = {
  __typename?: 'QueryBrandProductsConnection';
  edges: Array<QueryBrandProductsConnectionEdge>;
  pageInfo: PageInfo;
};

export type QueryBrandProductsConnectionEdge = {
  __typename?: 'QueryBrandProductsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: BrandProduct;
};

export type QueryUsersByBrandConnection = {
  __typename?: 'QueryUsersByBrandConnection';
  edges: Array<QueryUsersByBrandConnectionEdge>;
  pageInfo: PageInfo;
};

export type QueryUsersByBrandConnectionEdge = {
  __typename?: 'QueryUsersByBrandConnectionEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

/** Input type for redeemDiscountCode mutation */
export type RedeemDiscountCodeInput = {
  id: Scalars['Uuid']['input'];
};

/** Payload type for redeemDiscountCode mutation */
export type RedeemDiscountCodePayload = {
  __typename?: 'RedeemDiscountCodePayload';
  brandMember?: Maybe<BrandMember>;
  discountCode?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Input type for redeemYotpoPoints mutation */
export type RedeemYotpoPointsInput = {
  redemptionId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

/** Payload type for redeemYotpoPoints mutation */
export type RedeemYotpoPointsPayload = {
  __typename?: 'RedeemYotpoPointsPayload';
  brandMember: BrandMember;
  rewardText: Scalars['String']['output'];
};

/** Input type for registerLastOpenedNotificationsAt mutation */
export type RegisterLastOpenedNotificationsAtInput = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

/** Payload type for registerLastOpenedNotificationsAt mutation */
export type RegisterLastOpenedNotificationsAtPayload = {
  __typename?: 'RegisterLastOpenedNotificationsAtPayload';
  user: User;
};

/** Input type for registerShopifyShop mutation */
export type RegisterShopifyShopInput = {
  apiKey: Scalars['String']['input'];
  shopUrl: Scalars['String']['input'];
};

/** Payload type for registerShopifyShop mutation */
export type RegisterShopifyShopPayload = {
  __typename?: 'RegisterShopifyShopPayload';
  brand: Brand;
};

/** Input type for registerUser mutation */
export type RegisterUserInput = {
  autoJoinBrandId: Scalars['Uuid']['input'];
  data: UserUpdateData;
  id: Scalars['Uuid']['input'];
  optIntoEmailMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  referral?: InputMaybe<Scalars['String']['input']>;
};

/** Payload type for registerUser mutation */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user: User;
};

/** Input type for reorderRoomOptions mutation */
export type ReorderRoomOptionsInput = {
  brandId: Scalars['Uuid']['input'];
  roomOptionIds: Array<Scalars['Uuid']['input']>;
};

/** Payload type for reorderRoomOptions mutation */
export type ReorderRoomOptionsPayload = {
  __typename?: 'ReorderRoomOptionsPayload';
  roomOptions: Array<RoomOption>;
};

/** Input type for reportContent mutation */
export type ReportContentInput = {
  postCommentId?: InputMaybe<Scalars['Uuid']['input']>;
  postId?: InputMaybe<Scalars['Uuid']['input']>;
  reasonOther: Scalars['String']['input'];
  reportType: ReportType;
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

/** Payload type for reportContent mutation */
export type ReportContentPayload = {
  __typename?: 'ReportContentPayload';
  success: Scalars['Boolean']['output'];
};

export type ReportType =
  | 'CHALLENGE'
  | 'COMMENT'
  | 'POST'
  | 'SHELF'
  | 'SHELF_PRODUCT'
  | 'USER';

/** Input type for requestPhoneVerification mutation */
export type RequestPhoneVerificationInput = {
  phoneNumber: Scalars['String']['input'];
};

/** Payload type for requestPhoneVerification mutation */
export type RequestPhoneVerificationPayload = {
  __typename?: 'RequestPhoneVerificationPayload';
  phoneVerification: UserPhoneVerification;
  user: User;
};

export type RoomMember = {
  __typename?: 'RoomMember';
  isModerator: Scalars['Boolean']['output'];
  roomOption: RoomOption;
  roomOptionId: Scalars['Uuid']['output'];
  user: User;
  userId: Scalars['Uuid']['output'];
};

export type RoomMemberCreateData = {
  isModerator?: InputMaybe<Scalars['Boolean']['input']>;
  roomOptionId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type RoomMemberLabel = {
  __typename?: 'RoomMemberLabel';
  brandMemberLabelOption: BrandMemberLabelOption;
  brandMemberLabelOptionId: Scalars['Uuid']['output'];
  id: Scalars['ID']['output'];
  roomOption: RoomOption;
  roomOptionId: Scalars['Uuid']['output'];
};

export type RoomMemberPrimaryKey = {
  roomOptionId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

export type RoomMemberUpdateData = {
  isModerator?: InputMaybe<Scalars['Boolean']['input']>;
  roomOptionId?: InputMaybe<Scalars['Uuid']['input']>;
  userId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type RoomOption = {
  __typename?: 'RoomOption';
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefaultRoom: Scalars['Boolean']['output'];
  isLeavingRestricted: Scalars['Boolean']['output'];
  isReadOnly: Scalars['Boolean']['output'];
  isRoomRestricted: Scalars['Boolean']['output'];
  memberGroups: Array<RoomOptionMemberGroup>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  postCount: Scalars['Int']['output'];
  postPrompts: Array<PostPrompt>;
  requiresLogin: Scalars['Boolean']['output'];
  roomMemberLabels: Array<RoomMemberLabel>;
  roomMemberPermissions: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  type: RoomType;
};

export type RoomOptionCreateData = {
  brandId: Scalars['Uuid']['input'];
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Uuid']['input']>;
  isDefaultRoom?: InputMaybe<Scalars['Boolean']['input']>;
  isLeavingRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isRoomRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  memberGroups?: InputMaybe<Array<RoomOptionEmbeddedMemberGroupsData>>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  requiresLogin?: InputMaybe<Scalars['Boolean']['input']>;
  roomMemberLabels?: InputMaybe<Array<RoomOptionEmbeddedRoomMemberLabelsData>>;
  type: RoomType;
};

export type RoomOptionEmbeddedMemberGroupsData = {
  memberGroupId: Scalars['Uuid']['input'];
};

export type RoomOptionEmbeddedRoomMemberLabelsData = {
  brandMemberLabelOptionId: Scalars['Uuid']['input'];
  id?: InputMaybe<Scalars['Uuid']['input']>;
};

export type RoomOptionMemberGroup = {
  __typename?: 'RoomOptionMemberGroup';
  memberGroup: MemberGroup;
  memberGroupId: Scalars['Uuid']['output'];
  roomOption: RoomOption;
  roomOptionId: Scalars['Uuid']['output'];
};

export type RoomOptionUpdateData = {
  description?: InputMaybe<Scalars['String']['input']>;
  isDefaultRoom?: InputMaybe<Scalars['Boolean']['input']>;
  isLeavingRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isRoomRestricted?: InputMaybe<Scalars['Boolean']['input']>;
  memberGroups?: InputMaybe<Array<RoomOptionEmbeddedMemberGroupsData>>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  requiresLogin?: InputMaybe<Scalars['Boolean']['input']>;
  roomMemberLabels?: InputMaybe<Array<RoomOptionEmbeddedRoomMemberLabelsData>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<RoomType>;
};

export type RoomType =
  | 'CUSTOM'
  | 'GENERAL'
  | 'INTRODUCTIONS'
  | 'SUPPORT';

export type SearchLocationResult = {
  __typename?: 'SearchLocationResult';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

/** Input type for sendEmailVerification mutation */
export type SendEmailVerificationInput = {
  userId: Scalars['Uuid']['input'];
};

/** Payload type for sendEmailVerification mutation */
export type SendEmailVerificationPayload = {
  __typename?: 'SendEmailVerificationPayload';
  user: User;
};

/** Input type for setPostCommentLiked mutation */
export type SetPostCommentLikedInput = {
  commentId: Scalars['Uuid']['input'];
  isLiked: Scalars['Boolean']['input'];
};

/** Payload type for setPostCommentLiked mutation */
export type SetPostCommentLikedPayload = {
  __typename?: 'SetPostCommentLikedPayload';
  comment: PostComment;
};

/** Input type for setPostLiked mutation */
export type SetPostLikedInput = {
  isLiked: Scalars['Boolean']['input'];
  postId: Scalars['Uuid']['input'];
};

/** Payload type for setPostLiked mutation */
export type SetPostLikedPayload = {
  __typename?: 'SetPostLikedPayload';
  post: Post;
};

/** Input type for setShopifyProductSyncEnabled mutation */
export type SetShopifyProductSyncEnabledInput = {
  brandId: Scalars['Uuid']['input'];
  enabled: Scalars['Boolean']['input'];
};

/** Payload type for setShopifyProductSyncEnabled mutation */
export type SetShopifyProductSyncEnabledPayload = {
  __typename?: 'SetShopifyProductSyncEnabledPayload';
  brand: Brand;
};

export type Shelf = {
  __typename?: 'Shelf';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  products: Array<ShelfProduct>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type ShelfCreateData = {
  brandId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['Uuid']['input'];
};

export type ShelfProduct = {
  __typename?: 'ShelfProduct';
  brandProduct: BrandProduct;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  productId: Scalars['Uuid']['output'];
  shelf: Shelf;
  shelfId: Scalars['Uuid']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ShelfProductCreateData = {
  description?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Uuid']['input'];
  shelfId: Scalars['Uuid']['input'];
};

export type ShelfProductUpdateData = {
  description?: InputMaybe<Scalars['String']['input']>;
};

export type ShelfUpdate = {
  __typename?: 'ShelfUpdate';
  shelves: Array<Shelf>;
};

export type ShelfUpdateData = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ShelfWithProductCreateData = {
  brandId: Scalars['Uuid']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
  userId: Scalars['Uuid']['input'];
};

/** Input type for softDeletePostComment mutation */
export type SoftDeletePostCommentInput = {
  postCommentId: Scalars['Uuid']['input'];
};

/** Payload type for softDeletePostComment mutation */
export type SoftDeletePostCommentPayload = {
  __typename?: 'SoftDeletePostCommentPayload';
  comment: PostComment;
};

export type TraitColor = {
  __typename?: 'TraitColor';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Input type for uninstallYotpoDeveloperIntegration mutation */
export type UninstallYotpoDeveloperIntegrationInput = {
  brandId: Scalars['Uuid']['input'];
};

/** Payload type for uninstallYotpoDeveloperIntegration mutation */
export type UninstallYotpoDeveloperIntegrationPayload = {
  __typename?: 'UninstallYotpoDeveloperIntegrationPayload';
  brand: Brand;
};

/** Input type for unregisterShopifyShop mutation */
export type UnregisterShopifyShopInput = {
  shopUrl: Scalars['String']['input'];
};

/** Payload type for unregisterShopifyShop mutation */
export type UnregisterShopifyShopPayload = {
  __typename?: 'UnregisterShopifyShopPayload';
  brandFound: Scalars['Boolean']['output'];
};

/** Input type for unsubscribeUserUsingToken mutation */
export type UnsubscribeUserUsingTokenInput = {
  keys: Array<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Payload type for unsubscribeUserUsingToken mutation */
export type UnsubscribeUserUsingTokenPayload = {
  __typename?: 'UnsubscribeUserUsingTokenPayload';
  notificationTypes: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Input type for updateBannedWord mutation */
export type UpdateBannedWordInput = {
  data: BannedWordUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBannedWord mutation */
export type UpdateBannedWordPayload = {
  __typename?: 'UpdateBannedWordPayload';
  bannedWord: BannedWord;
};

/** Input type for updateBrandApiKey mutation */
export type UpdateBrandApiKeyInput = {
  data: BrandApiKeyUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandApiKey mutation */
export type UpdateBrandApiKeyPayload = {
  __typename?: 'UpdateBrandApiKeyPayload';
  brandApiKey: BrandApiKey;
};

/** Input type for updateBrandEvent mutation */
export type UpdateBrandEventInput = {
  data: BrandEventUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandEvent mutation */
export type UpdateBrandEventPayload = {
  __typename?: 'UpdateBrandEventPayload';
  brandEvent: BrandEvent;
};

/** Input type for updateBrand mutation */
export type UpdateBrandInput = {
  data: BrandUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Input type for updateBrandInveterateIntegration mutation */
export type UpdateBrandInveterateIntegrationInput = {
  brandId: Scalars['Uuid']['input'];
  isCommunityGateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  membershipJoinUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Payload type for updateBrandInveterateIntegration mutation */
export type UpdateBrandInveterateIntegrationPayload = {
  __typename?: 'UpdateBrandInveterateIntegrationPayload';
  brandInveterateIntegration: BrandInveterateIntegration;
};

/** Input type for updateBrandKlaviyoIntegration mutation */
export type UpdateBrandKlaviyoIntegrationInput = {
  data: BrandKlaviyoIntegrationUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandKlaviyoIntegration mutation */
export type UpdateBrandKlaviyoIntegrationPayload = {
  __typename?: 'UpdateBrandKlaviyoIntegrationPayload';
  brandKlaviyoIntegration: BrandKlaviyoIntegration;
};

/** Input type for updateBrandLink mutation */
export type UpdateBrandLinkInput = {
  data: BrandLinkUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandLink mutation */
export type UpdateBrandLinkPayload = {
  __typename?: 'UpdateBrandLinkPayload';
  brandLink: BrandLink;
};

/** Input type for updateBrandMemberBadge mutation */
export type UpdateBrandMemberBadgeInput = {
  data: BrandMemberBadgeUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandMemberBadge mutation */
export type UpdateBrandMemberBadgePayload = {
  __typename?: 'UpdateBrandMemberBadgePayload';
  brandMemberBadge: BrandMemberBadge;
};

/** Input type for updateBrandMember mutation */
export type UpdateBrandMemberInput = {
  data: BrandMemberUpdateData;
  id: BrandMemberPrimaryKey;
};

/** Input type for updateBrandMemberLabel mutation */
export type UpdateBrandMemberLabelInput = {
  data: BrandMemberLabelUpdateData;
  id: BrandMemberLabelPrimaryKey;
};

/** Input type for updateBrandMemberLabelOption mutation */
export type UpdateBrandMemberLabelOptionInput = {
  data: BrandMemberLabelOptionUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandMemberLabelOption mutation */
export type UpdateBrandMemberLabelOptionPayload = {
  __typename?: 'UpdateBrandMemberLabelOptionPayload';
  brandMemberLabelOption: BrandMemberLabelOption;
};

/** Payload type for updateBrandMemberLabel mutation */
export type UpdateBrandMemberLabelPayload = {
  __typename?: 'UpdateBrandMemberLabelPayload';
  brandMemberLabel: BrandMemberLabel;
};

/** Input type for updateBrandMemberNotificationSetting mutation */
export type UpdateBrandMemberNotificationSettingInput = {
  data: BrandMemberNotificationSettingUpdateData;
  id: BrandMemberNotificationSettingPrimaryKey;
};

/** Payload type for updateBrandMemberNotificationSetting mutation */
export type UpdateBrandMemberNotificationSettingPayload = {
  __typename?: 'UpdateBrandMemberNotificationSettingPayload';
  brandMemberNotificationSetting: BrandMemberNotificationSetting;
};

/** Payload type for updateBrandMember mutation */
export type UpdateBrandMemberPayload = {
  __typename?: 'UpdateBrandMemberPayload';
  brandMember: BrandMember;
};

/** Payload type for updateBrand mutation */
export type UpdateBrandPayload = {
  __typename?: 'UpdateBrandPayload';
  brand: Brand;
};

/** Input type for updateBrandWebhook mutation */
export type UpdateBrandWebhookInput = {
  data: BrandWebhookUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandWebhook mutation */
export type UpdateBrandWebhookPayload = {
  __typename?: 'UpdateBrandWebhookPayload';
  brandWebhook: BrandWebhook;
};

/** Input type for updateBrandYotpoDeveloperIntegration mutation */
export type UpdateBrandYotpoDeveloperIntegrationInput = {
  data: BrandYotpoDeveloperIntegrationUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateBrandYotpoDeveloperIntegration mutation */
export type UpdateBrandYotpoDeveloperIntegrationPayload = {
  __typename?: 'UpdateBrandYotpoDeveloperIntegrationPayload';
  brandYotpoDeveloperIntegration: BrandYotpoDeveloperIntegration;
};

/** Input type for updateBrandYotpoRedemptionUrl mutation */
export type UpdateBrandYotpoRedemptionUrlInput = {
  brandId: Scalars['Uuid']['input'];
  redemptionUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Payload type for updateBrandYotpoRedemptionUrl mutation */
export type UpdateBrandYotpoRedemptionUrlPayload = {
  __typename?: 'UpdateBrandYotpoRedemptionUrlPayload';
  brandYotpoIntegration: BrandYotpoIntegration;
};

/** Input type for updateChallenge mutation */
export type UpdateChallengeInput = {
  data: ChallengeUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Input type for updateChallengeInput mutation */
export type UpdateChallengeInputInput = {
  data: ChallengeInputUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateChallengeInput mutation */
export type UpdateChallengeInputPayload = {
  __typename?: 'UpdateChallengeInputPayload';
  challengeInput: ChallengeInput;
};

/** Input type for updateChallengeParticipant mutation */
export type UpdateChallengeParticipantInput = {
  data: ChallengeParticipantUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateChallengeParticipant mutation */
export type UpdateChallengeParticipantPayload = {
  __typename?: 'UpdateChallengeParticipantPayload';
  challengeParticipant: ChallengeParticipant;
};

/** Payload type for updateChallenge mutation */
export type UpdateChallengePayload = {
  __typename?: 'UpdateChallengePayload';
  challenge: Challenge;
};

/** Input type for updateComment mutation */
export type UpdateCommentInput = {
  attachments?: InputMaybe<Array<PostCommentEmbeddedAttachmentsData>>;
  commentId: Scalars['Uuid']['input'];
  contentHtml: Scalars['String']['input'];
  embeddedUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Payload type for updateComment mutation */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment: PostComment;
  post: Post;
};

/** Input type for updateCustomTraitGroup mutation */
export type UpdateCustomTraitGroupInput = {
  data: CustomTraitGroupUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateCustomTraitGroup mutation */
export type UpdateCustomTraitGroupPayload = {
  __typename?: 'UpdateCustomTraitGroupPayload';
  customTraitGroup: CustomTraitGroup;
};

/** Input type for updateCustomTraits mutation */
export type UpdateCustomTraitsInput = {
  brandId: Scalars['Uuid']['input'];
  traits: Array<CustomTraitBulkUpdateInput>;
};

/** Payload type for updateCustomTraits mutation */
export type UpdateCustomTraitsPayload = {
  __typename?: 'UpdateCustomTraitsPayload';
  customTraits: Array<CustomTrait>;
};

/** Input type for updateEventAttendee mutation */
export type UpdateEventAttendeeInput = {
  data: EventAttendeeUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateEventAttendee mutation */
export type UpdateEventAttendeePayload = {
  __typename?: 'UpdateEventAttendeePayload';
  eventAttendee: EventAttendee;
};

/** Input type for updateFaq mutation */
export type UpdateFaqInput = {
  data: FaqUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateFaq mutation */
export type UpdateFaqPayload = {
  __typename?: 'UpdateFaqPayload';
  faq: Faq;
};

/** Input type for updateLoyaltyAction mutation */
export type UpdateLoyaltyActionInput = {
  data: LoyaltyActionUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateLoyaltyAction mutation */
export type UpdateLoyaltyActionPayload = {
  __typename?: 'UpdateLoyaltyActionPayload';
  loyaltyAction: LoyaltyAction;
};

/** Input type for updateMemberGroup mutation */
export type UpdateMemberGroupInput = {
  data: MemberGroupUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateMemberGroup mutation */
export type UpdateMemberGroupPayload = {
  __typename?: 'UpdateMemberGroupPayload';
  memberGroup: MemberGroup;
};

/** Input type for updateOnboarding mutation */
export type UpdateOnboardingInput = {
  data: OnboardingUpdateData;
  id: OnboardingPrimaryKey;
};

/** Payload type for updateOnboarding mutation */
export type UpdateOnboardingPayload = {
  __typename?: 'UpdateOnboardingPayload';
  onboarding: Onboarding;
};

/** Input type for updatePost mutation */
export type UpdatePostInput = {
  data: PostUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updatePost mutation */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  post: Post;
};

/** Input type for updatePostPrompt mutation */
export type UpdatePostPromptInput = {
  data: PostPromptUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updatePostPrompt mutation */
export type UpdatePostPromptPayload = {
  __typename?: 'UpdatePostPromptPayload';
  postPrompt: PostPrompt;
};

/** Input type for updatePostTagOption mutation */
export type UpdatePostTagOptionInput = {
  data: PostTagOptionUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updatePostTagOption mutation */
export type UpdatePostTagOptionPayload = {
  __typename?: 'UpdatePostTagOptionPayload';
  postTagOption: PostTagOption;
};

/** Input type for updateRoomMember mutation */
export type UpdateRoomMemberInput = {
  data: RoomMemberUpdateData;
  id: RoomMemberPrimaryKey;
};

/** Payload type for updateRoomMember mutation */
export type UpdateRoomMemberPayload = {
  __typename?: 'UpdateRoomMemberPayload';
  roomMember: RoomMember;
};

/** Input type for updateRoomOption mutation */
export type UpdateRoomOptionInput = {
  data: RoomOptionUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateRoomOption mutation */
export type UpdateRoomOptionPayload = {
  __typename?: 'UpdateRoomOptionPayload';
  roomOption: RoomOption;
};

/** Input type for updateShelf mutation */
export type UpdateShelfInput = {
  data: ShelfUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateShelf mutation */
export type UpdateShelfPayload = {
  __typename?: 'UpdateShelfPayload';
  shelf: Shelf;
};

/** Input type for updateShelfProduct mutation */
export type UpdateShelfProductInput = {
  data: ShelfProductUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateShelfProduct mutation */
export type UpdateShelfProductPayload = {
  __typename?: 'UpdateShelfProductPayload';
  shelfProduct: ShelfProduct;
};

/** Input type for updateUser mutation */
export type UpdateUserInput = {
  data: UserUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Input type for updateUserLoyaltyPointsFromYotpo mutation */
export type UpdateUserLoyaltyPointsFromYotpoInput = {
  brandId: Scalars['Uuid']['input'];
  userId: Scalars['Uuid']['input'];
};

/** Payload type for updateUserLoyaltyPointsFromYotpo mutation */
export type UpdateUserLoyaltyPointsFromYotpoPayload = {
  __typename?: 'UpdateUserLoyaltyPointsFromYotpoPayload';
  brandMember: BrandMember;
};

/** Input type for updateUserNotification mutation */
export type UpdateUserNotificationInput = {
  data: UserNotificationUpdateData;
  id: Scalars['Uuid']['input'];
};

/** Payload type for updateUserNotification mutation */
export type UpdateUserNotificationPayload = {
  __typename?: 'UpdateUserNotificationPayload';
  userNotification: UserNotification;
};

/** Payload type for updateUser mutation */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type User = {
  __typename?: 'User';
  approximateAge?: Maybe<Scalars['Int']['output']>;
  avatarFile?: Maybe<File>;
  bio?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['Date']['output']>;
  brandAdminMemberships: Array<BrandMember>;
  brandLoyaltyPoints?: Maybe<Scalars['Int']['output']>;
  brandMemberBadges: Array<BrandMemberBadge>;
  brandMemberships: Array<BrandMember>;
  brandRole?: Maybe<BrandMemberRole>;
  customAuthBrand?: Maybe<Brand>;
  customTraitResponses?: Maybe<Array<CustomTraitResponse>>;
  dateJoined?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  eyeColor?: Maybe<TraitColor>;
  eyeColorId?: Maybe<Scalars['Uuid']['output']>;
  favoriteShoeType?: Maybe<UserShoeType>;
  favoriteShoeTypeId?: Maybe<Scalars['Uuid']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<UserGender>;
  hairColor?: Maybe<TraitColor>;
  hairColorId?: Maybe<Scalars['Uuid']['output']>;
  hairColorOther?: Maybe<Scalars['String']['output']>;
  hairLength?: Maybe<UserHairLength>;
  hairTexture?: Maybe<UserHairTexture>;
  hasAddedTraits: Scalars['Boolean']['output'];
  hasBunions?: Maybe<Scalars['Boolean']['output']>;
  hasOtherBrandMemberships: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBanned?: Maybe<Scalars['Boolean']['output']>;
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
  isModeratorForSomeRooms: Scalars['Boolean']['output'];
  isPhoneVerified?: Maybe<Scalars['Boolean']['output']>;
  isRegistered?: Maybe<Scalars['Boolean']['output']>;
  isSmsMarketingAllowed?: Maybe<Scalars['Boolean']['output']>;
  isTopContributor?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastOpenedNotificationsAt?: Maybe<Scalars['DateTime']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  notifications: Array<UserNotification>;
  onboarding?: Maybe<Onboarding>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postCommentMentions: Array<PostCommentMention>;
  postComments: Array<PostComment>;
  postCount?: Maybe<Scalars['Int']['output']>;
  postMentions: Array<PostMention>;
  posts: Array<Post>;
  profileBannerFile?: Maybe<File>;
  profilePoints?: Maybe<Scalars['Int']['output']>;
  pronouns?: Maybe<UserPronoun>;
  roomMemberships: Array<RoomMember>;
  shelves?: Maybe<Array<Shelf>>;
  shoeSize?: Maybe<UserShoeSize>;
  shoeSizeId?: Maybe<Scalars['Uuid']['output']>;
  shoeWidth?: Maybe<UserShoeWidth>;
  skinTone?: Maybe<Scalars['String']['output']>;
  skinToneCategory?: Maybe<UserSkinTone>;
  skinType?: Maybe<UserSkinType>;
  socialFacebook?: Maybe<Scalars['String']['output']>;
  socialInstagram?: Maybe<Scalars['String']['output']>;
  socialReddit?: Maybe<Scalars['String']['output']>;
  socialTiktok?: Maybe<Scalars['String']['output']>;
  /** Returns a suggested username based on the user first and last name */
  suggestedUsername: Scalars['String']['output'];
  tagline?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  topHairConcern?: Maybe<UserTopHairConcern>;
  topSkinConcern?: Maybe<UserTopSkinConcern>;
  userLocation?: Maybe<UserLocation>;
  userLocationId?: Maybe<Scalars['Uuid']['output']>;
  userLocationKey?: Maybe<Scalars['String']['output']>;
  userModeratedRooms: Array<RoomOption>;
  userPhoneVerifications: Array<UserPhoneVerification>;
  username?: Maybe<Scalars['String']['output']>;
};


export type UserBrandLoyaltyPointsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserBrandMemberBadgesArgs = {
  brandId: Scalars['Uuid']['input'];
  roomId?: InputMaybe<Scalars['Uuid']['input']>;
};


export type UserBrandRoleArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserDateJoinedArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserHasAddedTraitsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserIsBannedArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserIsModeratorForSomeRoomsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserIsTopContributorArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserLastOpenedNotificationsAtArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserOnboardingArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserPostCountArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserProfilePointsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserRoomMembershipsArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserShelvesArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserSuggestedUsernameArgs = {
  brandId: Scalars['Uuid']['input'];
};


export type UserUserModeratedRoomsArgs = {
  brandId: Scalars['Uuid']['input'];
};

export type UserCreateData = {
  avatarFile?: InputMaybe<FileUploadInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  brandMemberships?: InputMaybe<Array<UserEmbeddedBrandMembershipsData>>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<UserGender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  profileBannerFile?: InputMaybe<FileUploadInput>;
  pronouns?: InputMaybe<UserPronoun>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserEmbeddedBrandMembershipsData = {
  brandId: Scalars['Uuid']['input'];
  hasEmailMarketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  isBanned?: InputMaybe<Scalars['Boolean']['input']>;
  referrerUserId?: InputMaybe<Scalars['Uuid']['input']>;
  role: BrandMemberRole;
};

export type UserEmbeddedCustomTraitResponsesData = {
  text?: InputMaybe<Scalars['String']['input']>;
  traitId: Scalars['Uuid']['input'];
  traitOptionId?: InputMaybe<Scalars['Uuid']['input']>;
};

export type UserFilterByBrandInput = {
  brandId: Scalars['Uuid']['input'];
  favoriteShoeTypeId?: InputMaybe<Scalars['Uuid']['input']>;
  hairColorId?: InputMaybe<Scalars['Uuid']['input']>;
  hairLength?: InputMaybe<UserHairLength>;
  hairTexture?: InputMaybe<UserHairTexture>;
  hasBunions?: InputMaybe<Scalars['Boolean']['input']>;
  isTopContributor?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['Uuid']['input']>;
  selectedCustomOptionIds?: InputMaybe<Array<Scalars['Uuid']['input']>>;
  shoeSizeId?: InputMaybe<Scalars['Uuid']['input']>;
  shoeWidth?: InputMaybe<UserShoeWidth>;
  skinToneCategory?: InputMaybe<UserSkinTone>;
  skinType?: InputMaybe<UserSkinType>;
  topHairConcern?: InputMaybe<UserTopHairConcern>;
  topSkinConcern?: InputMaybe<UserTopSkinConcern>;
};

export type UserGender =
  | 'FEMALE'
  | 'MALE'
  | 'OTHER'
  | 'PREFER_NOT_TO_SAY';

export type UserHairLength =
  | 'BUZZ_CUT'
  | 'LONG'
  | 'MID'
  | 'SHORT';

export type UserHairTexture =
  | 'COILY_4A'
  | 'COILY_4B'
  | 'COILY_4C'
  | 'CURLY_3A'
  | 'CURLY_3B'
  | 'CURLY_3C'
  | 'STRAIGHT'
  | 'WAVY_2A'
  | 'WAVY_2B'
  | 'WAVY_2C';

export type UserLocation = {
  __typename?: 'UserLocation';
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationKey: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  regionCode?: Maybe<Scalars['String']['output']>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  brandId: Scalars['Uuid']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  post?: Maybe<Post>;
  postComment?: Maybe<PostComment>;
  postCommentId?: Maybe<Scalars['Uuid']['output']>;
  postId?: Maybe<Scalars['Uuid']['output']>;
  referencedUserOne?: Maybe<User>;
  referencedUserOneId?: Maybe<Scalars['Uuid']['output']>;
  referencedUserTwo?: Maybe<User>;
  referencedUserTwoId?: Maybe<Scalars['Uuid']['output']>;
  type: UserNotificationType;
  updatedAt: Scalars['DateTime']['output'];
  userCount?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['Uuid']['output'];
};

export type UserNotificationConnection = {
  __typename?: 'UserNotificationConnection';
  edges: Array<UserNotificationEdge>;
  pageInfo: PageInfo;
};

export type UserNotificationEdge = {
  __typename?: 'UserNotificationEdge';
  cursor: Scalars['String']['output'];
  node: UserNotification;
};

export type UserNotificationType =
  | 'COMMENT_LIKE'
  | 'COMMENT_MENTION'
  | 'POST_APPROVED'
  | 'POST_LIKE'
  | 'POST_MENTION'
  | 'POST_NEW'
  | 'POST_POLL'
  | 'REPLY_TO_COMMENT'
  | 'REPLY_TO_POST';

export type UserNotificationUpdateData = {
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserPhoneVerification = {
  __typename?: 'UserPhoneVerification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastAttemptAt: Scalars['DateTime']['output'];
  numRetries: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Uuid']['output'];
  verifiedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserPronoun =
  | 'HE_HIM'
  | 'OTHER'
  | 'PREFER_NOT_TO_SAY'
  | 'SHE_HER'
  | 'THEY_THEM';

export type UserShoeSize = {
  __typename?: 'UserShoeSize';
  euSize: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMale: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  ukSize: Scalars['String']['output'];
  usSize: Scalars['String']['output'];
};

export type UserShoeType = {
  __typename?: 'UserShoeType';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserShoeWidth =
  | 'EXTRA_WIDE'
  | 'MEDIUM'
  | 'NARROW'
  | 'WIDE';

export type UserSkinTone =
  | 'DARK'
  | 'FAIR'
  | 'LIGHT'
  | 'MEDIUM';

export type UserSkinType =
  | 'COMBINATION'
  | 'DRY'
  | 'NORMAL'
  | 'OILY'
  | 'SENSITIVE';

export type UserTopHairConcern =
  | 'DAMAGED'
  | 'DRY_SCALP'
  | 'FRIZZ'
  | 'HAIR_COLOR'
  | 'NO_VOLUME'
  | 'THINNING';

export type UserTopSkinConcern =
  | 'ACNE'
  | 'DRY_SKIN'
  | 'ITCHINESS'
  | 'PSORIASIS'
  | 'ROUGH_BUMPY'
  | 'SENSITIVITY';

export type UserUpdateData = {
  avatarFile?: InputMaybe<FileUploadInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['Date']['input']>;
  brandMemberships?: InputMaybe<Array<UserEmbeddedBrandMembershipsData>>;
  customTraitResponses?: InputMaybe<Array<UserEmbeddedCustomTraitResponsesData>>;
  email?: InputMaybe<Scalars['String']['input']>;
  eyeColorId?: InputMaybe<Scalars['Uuid']['input']>;
  favoriteShoeTypeId?: InputMaybe<Scalars['Uuid']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<UserGender>;
  hairColorId?: InputMaybe<Scalars['Uuid']['input']>;
  hairColorOther?: InputMaybe<Scalars['String']['input']>;
  hairLength?: InputMaybe<UserHairLength>;
  hairTexture?: InputMaybe<UserHairTexture>;
  hasBunions?: InputMaybe<Scalars['Boolean']['input']>;
  isSmsMarketingAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileBannerFile?: InputMaybe<FileUploadInput>;
  pronouns?: InputMaybe<UserPronoun>;
  shoeSizeId?: InputMaybe<Scalars['Uuid']['input']>;
  shoeWidth?: InputMaybe<UserShoeWidth>;
  skinTone?: InputMaybe<Scalars['String']['input']>;
  skinToneCategory?: InputMaybe<UserSkinTone>;
  skinType?: InputMaybe<UserSkinType>;
  socialFacebook?: InputMaybe<Scalars['String']['input']>;
  socialInstagram?: InputMaybe<Scalars['String']['input']>;
  socialReddit?: InputMaybe<Scalars['String']['input']>;
  socialTiktok?: InputMaybe<Scalars['String']['input']>;
  tagline?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  topHairConcern?: InputMaybe<UserTopHairConcern>;
  topSkinConcern?: InputMaybe<UserTopSkinConcern>;
  userLocationKey?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for visitBrand mutation */
export type VisitBrandInput = {
  brandId: Scalars['Uuid']['input'];
  /** @deprecated No longer needed as stored on user directly */
  tzOffset?: InputMaybe<Scalars['Int']['input']>;
};

/** Payload type for visitBrand mutation */
export type VisitBrandPayload = {
  __typename?: 'VisitBrandPayload';
  success: Scalars['Boolean']['output'];
};

export type WelcomeEmailSeries = {
  __typename?: 'WelcomeEmailSeries';
  completeProfileEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  introEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  loyaltyEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  promptToPostEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  topPostsEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type YotpoList = {
  __typename?: 'YotpoList';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  totalContacts: Scalars['Int']['output'];
};

export type YotpoLoyaltyLog = {
  __typename?: 'YotpoLoyaltyLog';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  points: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type CurrentUserFragment = { __typename?: 'User', id: string, email: string };

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['Uuid']['input'];
}>;


export type GetUserByIdQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string } };

export type BrandMemberBrandOptionFragment = { __typename?: 'Brand', name: string, id: string };

export type GetBrandMemberBrandOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBrandMemberBrandOptionsQuery = { __typename?: 'Query', brands: Array<{ __typename?: 'Brand', name: string, id: string }> };

export type UserRowFragment = { __typename?: 'User', id: string, email: string, username?: string | null, customAuthBrand?: { __typename?: 'Brand', id: string, name: string } | null };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, email: string, username?: string | null, customAuthBrand?: { __typename?: 'Brand', id: string, name: string } | null }> };

export type UserEditFragment = { __typename?: 'User', id: string, email: string, username?: string | null, firstName?: string | null, lastName?: string | null, customAuthBrand?: { __typename?: 'Brand', id: string, name: string } | null, brandMemberships: Array<{ __typename?: 'BrandMember', role: BrandMemberRole, isBanned?: boolean | null, brandId: string }> };

export type UserEditByIdQueryVariables = Exact<{
  id: Scalars['Uuid']['input'];
}>;


export type UserEditByIdQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: string, username?: string | null, firstName?: string | null, lastName?: string | null, customAuthBrand?: { __typename?: 'Brand', id: string, name: string } | null, brandMemberships: Array<{ __typename?: 'BrandMember', role: BrandMemberRole, isBanned?: boolean | null, brandId: string }> } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UpdateUserPayload', user: { __typename?: 'User', id: string, email: string, username?: string | null, firstName?: string | null, lastName?: string | null, customAuthBrand?: { __typename?: 'Brand', id: string, name: string } | null, brandMemberships: Array<{ __typename?: 'BrandMember', role: BrandMemberRole, isBanned?: boolean | null, brandId: string }> } } };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'DeleteUserPayload', user: { __typename?: 'User', id: string } } };

export type BrandRowFragment = { __typename?: 'Brand', id: string, name: string, communityDomain: string, createdAt: string };

export type GetBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBrandsQuery = { __typename?: 'Query', brands: Array<{ __typename?: 'Brand', id: string, name: string, communityDomain: string, createdAt: string }> };

export type BrandEditFragment = { __typename?: 'Brand', id: string, name: string, shopUrl?: string | null, communityDomain: string, analyticsKey?: string | null, isRestricted?: boolean | null, isTrial?: boolean | null, trialEndsAt?: string | null, isPhoneVerificationEnabled?: boolean | null, usesCustomAuth: boolean, customAuthUrl?: string | null, customLogoutUrl?: string | null, customUserSettingsUrl?: string | null, shouldShowPWA: boolean, customOauth?: { __typename?: 'BrandCustomOauth', clientId: string, authorizeUrl: string, accessUrl: string, clientSecret: string, jwksEndpointUrl: string, jwtIssuer: string } | null };

export type BrandEditByIdQueryVariables = Exact<{
  id: Scalars['Uuid']['input'];
}>;


export type BrandEditByIdQuery = { __typename?: 'Query', brand: { __typename?: 'Brand', id: string, name: string, shopUrl?: string | null, communityDomain: string, analyticsKey?: string | null, isRestricted?: boolean | null, isTrial?: boolean | null, trialEndsAt?: string | null, isPhoneVerificationEnabled?: boolean | null, usesCustomAuth: boolean, customAuthUrl?: string | null, customLogoutUrl?: string | null, customUserSettingsUrl?: string | null, shouldShowPWA: boolean, customOauth?: { __typename?: 'BrandCustomOauth', clientId: string, authorizeUrl: string, accessUrl: string, clientSecret: string, jwksEndpointUrl: string, jwtIssuer: string } | null } };

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;


export type CreateBrandMutation = { __typename?: 'Mutation', createBrand: { __typename?: 'CreateBrandPayload', brand: { __typename?: 'Brand', id: string, name: string, shopUrl?: string | null, communityDomain: string, analyticsKey?: string | null, isRestricted?: boolean | null, isTrial?: boolean | null, trialEndsAt?: string | null, isPhoneVerificationEnabled?: boolean | null, usesCustomAuth: boolean, customAuthUrl?: string | null, customLogoutUrl?: string | null, customUserSettingsUrl?: string | null, shouldShowPWA: boolean, customOauth?: { __typename?: 'BrandCustomOauth', clientId: string, authorizeUrl: string, accessUrl: string, clientSecret: string, jwksEndpointUrl: string, jwtIssuer: string } | null } } };

export type UpdateBrandMutationVariables = Exact<{
  input: UpdateBrandInput;
}>;


export type UpdateBrandMutation = { __typename?: 'Mutation', updateBrand: { __typename?: 'UpdateBrandPayload', brand: { __typename?: 'Brand', id: string, name: string, shopUrl?: string | null, communityDomain: string, analyticsKey?: string | null, isRestricted?: boolean | null, isTrial?: boolean | null, trialEndsAt?: string | null, isPhoneVerificationEnabled?: boolean | null, usesCustomAuth: boolean, customAuthUrl?: string | null, customLogoutUrl?: string | null, customUserSettingsUrl?: string | null, shouldShowPWA: boolean, postCount: number, memberCount: number, customOauth?: { __typename?: 'BrandCustomOauth', clientId: string, authorizeUrl: string, accessUrl: string, clientSecret: string, jwksEndpointUrl: string, jwtIssuer: string } | null, logoFile?: { __typename?: 'File', id: string, hostedUrl?: string | null } | null } } };

export type DeleteBrandMutationVariables = Exact<{
  input: DeleteBrandInput;
}>;


export type DeleteBrandMutation = { __typename?: 'Mutation', deleteBrand: { __typename?: 'DeleteBrandPayload', brand: { __typename?: 'Brand', id: string } } };

export type BrandShowFragment = { __typename?: 'Brand', id: string, name: string, shopUrl?: string | null, communityDomain: string, analyticsKey?: string | null, isRestricted?: boolean | null, isTrial?: boolean | null, trialEndsAt?: string | null, isPhoneVerificationEnabled?: boolean | null, shouldShowPWA: boolean, usesCustomAuth: boolean, customAuthUrl?: string | null, customLogoutUrl?: string | null, customUserSettingsUrl?: string | null, postCount: number, memberCount: number, logoFile?: { __typename?: 'File', id: string, hostedUrl?: string | null } | null, customOauth?: { __typename?: 'BrandCustomOauth', clientId: string, authorizeUrl: string, accessUrl: string, clientSecret: string, jwksEndpointUrl: string, jwtIssuer: string } | null };

export type BrandShowByIdQueryVariables = Exact<{
  id: Scalars['Uuid']['input'];
}>;


export type BrandShowByIdQuery = { __typename?: 'Query', brand: { __typename?: 'Brand', id: string, name: string, shopUrl?: string | null, communityDomain: string, analyticsKey?: string | null, isRestricted?: boolean | null, isTrial?: boolean | null, trialEndsAt?: string | null, isPhoneVerificationEnabled?: boolean | null, shouldShowPWA: boolean, usesCustomAuth: boolean, customAuthUrl?: string | null, customLogoutUrl?: string | null, customUserSettingsUrl?: string | null, postCount: number, memberCount: number, logoFile?: { __typename?: 'File', id: string, hostedUrl?: string | null } | null, customOauth?: { __typename?: 'BrandCustomOauth', clientId: string, authorizeUrl: string, accessUrl: string, clientSecret: string, jwksEndpointUrl: string, jwtIssuer: string } | null } };

export type CreateBullBoardAuthCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateBullBoardAuthCodeMutation = { __typename?: 'Mutation', createBullBoardAuthCode: { __typename?: 'CreateBullBoardAuthCodePayload', code: string } };

export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on User {
  id
  email
}
    `;
export const BrandMemberBrandOptionFragmentDoc = gql`
    fragment BrandMemberBrandOption on Brand {
  name
  id
}
    `;
export const UserRowFragmentDoc = gql`
    fragment UserRow on User {
  id
  email
  username
  customAuthBrand {
    id
    name
  }
}
    `;
export const UserEditFragmentDoc = gql`
    fragment UserEdit on User {
  id
  email
  customAuthBrand {
    id
    name
  }
  username
  firstName
  lastName
  brandMemberships {
    role
    isBanned
    brandId
  }
}
    `;
export const BrandRowFragmentDoc = gql`
    fragment BrandRow on Brand {
  id
  name
  communityDomain
  createdAt
}
    `;
export const BrandEditFragmentDoc = gql`
    fragment BrandEdit on Brand {
  id
  name
  shopUrl
  communityDomain
  analyticsKey
  isRestricted
  isTrial
  trialEndsAt
  isPhoneVerificationEnabled
  usesCustomAuth
  customAuthUrl
  customLogoutUrl
  customUserSettingsUrl
  shouldShowPWA
  customOauth {
    clientId
    authorizeUrl
    accessUrl
    clientSecret
    jwksEndpointUrl
    jwtIssuer
  }
}
    `;
export const BrandShowFragmentDoc = gql`
    fragment BrandShow on Brand {
  id
  name
  shopUrl
  logoFile {
    id
    hostedUrl
  }
  communityDomain
  analyticsKey
  isRestricted
  isTrial
  trialEndsAt
  isPhoneVerificationEnabled
  shouldShowPWA
  usesCustomAuth
  customAuthUrl
  customLogoutUrl
  customUserSettingsUrl
  customOauth {
    clientId
    authorizeUrl
    accessUrl
    clientSecret
    jwksEndpointUrl
    jwtIssuer
  }
  postCount
  memberCount
}
    `;
export const GetUserByIdDocument = gql`
    query getUserById($id: Uuid!) {
  user(id: $id) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables> & ({ variables: GetUserByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export function useGetUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdSuspenseQueryHookResult = ReturnType<typeof useGetUserByIdSuspenseQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetBrandMemberBrandOptionsDocument = gql`
    query GetBrandMemberBrandOptions {
  brands {
    ...BrandMemberBrandOption
  }
}
    ${BrandMemberBrandOptionFragmentDoc}`;

/**
 * __useGetBrandMemberBrandOptionsQuery__
 *
 * To run a query within a React component, call `useGetBrandMemberBrandOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandMemberBrandOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandMemberBrandOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandMemberBrandOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandMemberBrandOptionsQuery, GetBrandMemberBrandOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandMemberBrandOptionsQuery, GetBrandMemberBrandOptionsQueryVariables>(GetBrandMemberBrandOptionsDocument, options);
      }
export function useGetBrandMemberBrandOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandMemberBrandOptionsQuery, GetBrandMemberBrandOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandMemberBrandOptionsQuery, GetBrandMemberBrandOptionsQueryVariables>(GetBrandMemberBrandOptionsDocument, options);
        }
export function useGetBrandMemberBrandOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandMemberBrandOptionsQuery, GetBrandMemberBrandOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandMemberBrandOptionsQuery, GetBrandMemberBrandOptionsQueryVariables>(GetBrandMemberBrandOptionsDocument, options);
        }
export type GetBrandMemberBrandOptionsQueryHookResult = ReturnType<typeof useGetBrandMemberBrandOptionsQuery>;
export type GetBrandMemberBrandOptionsLazyQueryHookResult = ReturnType<typeof useGetBrandMemberBrandOptionsLazyQuery>;
export type GetBrandMemberBrandOptionsSuspenseQueryHookResult = ReturnType<typeof useGetBrandMemberBrandOptionsSuspenseQuery>;
export type GetBrandMemberBrandOptionsQueryResult = Apollo.QueryResult<GetBrandMemberBrandOptionsQuery, GetBrandMemberBrandOptionsQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    ...UserRow
  }
}
    ${UserRowFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export function useGetUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UserEditByIdDocument = gql`
    query UserEditById($id: Uuid!) {
  user(id: $id) {
    ...UserEdit
  }
}
    ${UserEditFragmentDoc}`;

/**
 * __useUserEditByIdQuery__
 *
 * To run a query within a React component, call `useUserEditByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserEditByIdQuery(baseOptions: Apollo.QueryHookOptions<UserEditByIdQuery, UserEditByIdQueryVariables> & ({ variables: UserEditByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditByIdQuery, UserEditByIdQueryVariables>(UserEditByIdDocument, options);
      }
export function useUserEditByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditByIdQuery, UserEditByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditByIdQuery, UserEditByIdQueryVariables>(UserEditByIdDocument, options);
        }
export function useUserEditByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserEditByIdQuery, UserEditByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEditByIdQuery, UserEditByIdQueryVariables>(UserEditByIdDocument, options);
        }
export type UserEditByIdQueryHookResult = ReturnType<typeof useUserEditByIdQuery>;
export type UserEditByIdLazyQueryHookResult = ReturnType<typeof useUserEditByIdLazyQuery>;
export type UserEditByIdSuspenseQueryHookResult = ReturnType<typeof useUserEditByIdSuspenseQuery>;
export type UserEditByIdQueryResult = Apollo.QueryResult<UserEditByIdQuery, UserEditByIdQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      ...UserEdit
    }
  }
}
    ${UserEditFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    user {
      id
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetBrandsDocument = gql`
    query GetBrands {
  brands {
    ...BrandRow
  }
}
    ${BrandRowFragmentDoc}`;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const BrandEditByIdDocument = gql`
    query BrandEditById($id: Uuid!) {
  brand(id: $id) {
    ...BrandEdit
  }
}
    ${BrandEditFragmentDoc}`;

/**
 * __useBrandEditByIdQuery__
 *
 * To run a query within a React component, call `useBrandEditByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandEditByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandEditByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandEditByIdQuery(baseOptions: Apollo.QueryHookOptions<BrandEditByIdQuery, BrandEditByIdQueryVariables> & ({ variables: BrandEditByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandEditByIdQuery, BrandEditByIdQueryVariables>(BrandEditByIdDocument, options);
      }
export function useBrandEditByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandEditByIdQuery, BrandEditByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandEditByIdQuery, BrandEditByIdQueryVariables>(BrandEditByIdDocument, options);
        }
export function useBrandEditByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrandEditByIdQuery, BrandEditByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrandEditByIdQuery, BrandEditByIdQueryVariables>(BrandEditByIdDocument, options);
        }
export type BrandEditByIdQueryHookResult = ReturnType<typeof useBrandEditByIdQuery>;
export type BrandEditByIdLazyQueryHookResult = ReturnType<typeof useBrandEditByIdLazyQuery>;
export type BrandEditByIdSuspenseQueryHookResult = ReturnType<typeof useBrandEditByIdSuspenseQuery>;
export type BrandEditByIdQueryResult = Apollo.QueryResult<BrandEditByIdQuery, BrandEditByIdQueryVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    brand {
      ...BrandEdit
    }
  }
}
    ${BrandEditFragmentDoc}`;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const UpdateBrandDocument = gql`
    mutation UpdateBrand($input: UpdateBrandInput!) {
  updateBrand(input: $input) {
    brand {
      ...BrandEdit
      ...BrandShow
    }
  }
}
    ${BrandEditFragmentDoc}
${BrandShowFragmentDoc}`;
export type UpdateBrandMutationFn = Apollo.MutationFunction<UpdateBrandMutation, UpdateBrandMutationVariables>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandMutation, UpdateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(UpdateBrandDocument, options);
      }
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<UpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<UpdateBrandMutation, UpdateBrandMutationVariables>;
export const DeleteBrandDocument = gql`
    mutation DeleteBrand($input: DeleteBrandInput!) {
  deleteBrand(input: $input) {
    brand {
      id
    }
  }
}
    `;
export type DeleteBrandMutationFn = Apollo.MutationFunction<DeleteBrandMutation, DeleteBrandMutationVariables>;

/**
 * __useDeleteBrandMutation__
 *
 * To run a mutation, you first call `useDeleteBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandMutation, { data, loading, error }] = useDeleteBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBrandMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBrandMutation, DeleteBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBrandMutation, DeleteBrandMutationVariables>(DeleteBrandDocument, options);
      }
export type DeleteBrandMutationHookResult = ReturnType<typeof useDeleteBrandMutation>;
export type DeleteBrandMutationResult = Apollo.MutationResult<DeleteBrandMutation>;
export type DeleteBrandMutationOptions = Apollo.BaseMutationOptions<DeleteBrandMutation, DeleteBrandMutationVariables>;
export const BrandShowByIdDocument = gql`
    query BrandShowById($id: Uuid!) {
  brand(id: $id) {
    ...BrandShow
  }
}
    ${BrandShowFragmentDoc}`;

/**
 * __useBrandShowByIdQuery__
 *
 * To run a query within a React component, call `useBrandShowByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandShowByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandShowByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandShowByIdQuery(baseOptions: Apollo.QueryHookOptions<BrandShowByIdQuery, BrandShowByIdQueryVariables> & ({ variables: BrandShowByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandShowByIdQuery, BrandShowByIdQueryVariables>(BrandShowByIdDocument, options);
      }
export function useBrandShowByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandShowByIdQuery, BrandShowByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandShowByIdQuery, BrandShowByIdQueryVariables>(BrandShowByIdDocument, options);
        }
export function useBrandShowByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrandShowByIdQuery, BrandShowByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrandShowByIdQuery, BrandShowByIdQueryVariables>(BrandShowByIdDocument, options);
        }
export type BrandShowByIdQueryHookResult = ReturnType<typeof useBrandShowByIdQuery>;
export type BrandShowByIdLazyQueryHookResult = ReturnType<typeof useBrandShowByIdLazyQuery>;
export type BrandShowByIdSuspenseQueryHookResult = ReturnType<typeof useBrandShowByIdSuspenseQuery>;
export type BrandShowByIdQueryResult = Apollo.QueryResult<BrandShowByIdQuery, BrandShowByIdQueryVariables>;
export const CreateBullBoardAuthCodeDocument = gql`
    mutation CreateBullBoardAuthCode {
  createBullBoardAuthCode {
    code
  }
}
    `;
export type CreateBullBoardAuthCodeMutationFn = Apollo.MutationFunction<CreateBullBoardAuthCodeMutation, CreateBullBoardAuthCodeMutationVariables>;

/**
 * __useCreateBullBoardAuthCodeMutation__
 *
 * To run a mutation, you first call `useCreateBullBoardAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBullBoardAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBullBoardAuthCodeMutation, { data, loading, error }] = useCreateBullBoardAuthCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateBullBoardAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateBullBoardAuthCodeMutation, CreateBullBoardAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBullBoardAuthCodeMutation, CreateBullBoardAuthCodeMutationVariables>(CreateBullBoardAuthCodeDocument, options);
      }
export type CreateBullBoardAuthCodeMutationHookResult = ReturnType<typeof useCreateBullBoardAuthCodeMutation>;
export type CreateBullBoardAuthCodeMutationResult = Apollo.MutationResult<CreateBullBoardAuthCodeMutation>;
export type CreateBullBoardAuthCodeMutationOptions = Apollo.BaseMutationOptions<CreateBullBoardAuthCodeMutation, CreateBullBoardAuthCodeMutationVariables>;